import { useEffect } from 'react'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import FingerprintRoundedIcon from '@mui/icons-material/FingerprintRounded'
import { useNavigate } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import { ViewStackUi } from './styles/style'
import useForm from './hooks/useFom'
import { Button, Form, InputC, ViewStack } from '../../components'
import { useAuth } from '../../hooks'
import BackgroundMiramar from '../../assets/images/login-view/backgroundLogin.svg'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box } from '@mui/material'

const SignUp = (): JSX.Element => {
  const { formik, loading } = useForm()
  const navigate = useNavigate()
  const { status } = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (status) {
      navigate('/')
    }
  }, [status])

  return (
    <ViewStackUi backgroundColor='#E6F3FF'>
      <Toaster position="top-center" reverseOrder={false} />
      {!isMobile && (
        <ViewStack bgcolor='transparent' important paddingX={4} width={0.40} margin={'auto'}>
          <img
            width={'70%'}
            src={BackgroundMiramar}
            alt="Logo Miramar"
            style={{ display: 'block', margin: '0 auto' }}
          />
        </ViewStack>
      )}
      <ViewStack
        important
        paddingX={isMobile ? 2 : 4}
        flexDirection={isMobile ? 'column' : 'row'}
        width={isMobile ? 1 : 0.6}
        margin={'auto'}
        gradientColor={isMobile ? 'linear-gradient(180deg, rgb(6 218 193 / 83%) 0%, rgba(255, 255, 255, 0.5) 100%)' : ''}
      >
        <>
        {isMobile
          ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2, flexDirection: 'column' }}>
            <Box mt={2} mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                width={'250px'}
                src={BackgroundMiramar}
                alt="Logo Miramar"
                style={{ display: 'block', margin: '0 auto' }}
              />
            </Box>
            <h1 style={{ color: '#000' }}>Iniciar Sesión</h1>
        </Box>)
          : (
              null
            )}
        </>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Form>
            <InputC
              fullWidth
              placeholder="Ingresa tu correo electrónico aquí"
              label="Correo electrónico"
              name="email"
              variant="outlined"
              startDecorator={<EmailRoundedIcon />}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <InputC
              fullWidth
              placeholder="Ingresa tu contraseña aquí"
              label="Contraseña"
              name="password"
              variant="outlined"
              type="password"
              startDecorator={<FingerprintRoundedIcon />}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <Button
              type="submit"
              variant="solid"
              backgroundColor="rgba(231, 203, 67, 1)"
              color="#000"
              width={isMobile ? '100%' : '60%'}
            >
              {loading ? 'Loading...' : 'Iniciar Sesion'}
            </Button>
          </Form>
        </form>
      </ViewStack>
    </ViewStackUi>
  )
}

export default SignUp
