import React, { useState } from 'react'
import {
  Typography,
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material'

interface Assignment {
  id: number
  name: string
  grade: number | null
  comments: string
}

const StudentGrading = (): JSX.Element => {
  const [assignments, setAssignments] = useState<Assignment[]>([
    { id: 1, name: 'Tarea 1', grade: null, comments: '' },
    { id: 2, name: 'Tarea 2', grade: null, comments: '' },
    { id: 3, name: 'Proyecto Final', grade: null, comments: '' }
  ])

  const [selectedAssignment, setSelectedAssignment] = useState<Assignment | null>(null)

  const handleGradeChange = (value: string): void => {
    if (selectedAssignment) {
      const grade = value === '' ? null : Number(value)
      setSelectedAssignment({ ...selectedAssignment, grade })
    }
  }

  const handleCommentChange = (value: string): void => {
    if (selectedAssignment) {
      setSelectedAssignment({ ...selectedAssignment, comments: value })
    }
  }

  const saveChanges = (): void => {
    if (selectedAssignment) {
      setAssignments(assignments.map(a =>
        a.id === selectedAssignment.id ? selectedAssignment : a
      ))
      setSelectedAssignment(null)
    }
  }

  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Tareas
              </Typography>
              <List>
                {assignments.map((assignment) => (
                  <React.Fragment key={assignment.id}>
                    <ListItem
                      button
                      onClick={() => { setSelectedAssignment(assignment) }}
                      selected={selectedAssignment?.id === assignment.id}
                    >
                      <ListItemText
                        primary={assignment.name}
                        secondary={`Calificación: ${assignment.grade ?? 'No calificado'}`}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 2 }}>
              {selectedAssignment
                ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    {selectedAssignment.name}
                  </Typography>
                  <TextField
                    label="Calificación"
                    type="number"
                    value={selectedAssignment.grade ?? ''}
                    onChange={(e) => { handleGradeChange(e.target.value) }}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Comentarios"
                    multiline
                    rows={4}
                    value={selectedAssignment.comments}
                    onChange={(e) => { handleCommentChange(e.target.value) }}
                    fullWidth
                    margin="normal"
                  />
                  <Button variant="contained" color="primary" onClick={saveChanges} sx={{ mt: 2 }}>
                    Guardar Cambios
                  </Button>
                </>
                  )
                : (
                <Typography>Selecciona una tarea para calificar</Typography>
                  )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default StudentGrading
