import * as Yup from 'yup'
import { useFormik, type FormikProps } from 'formik'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { getOnlyGrp, createTeacher } from '../../../redux/actions'
import useFirebase from '../../../hooks/FirebseHooks/useFirebase'
import { type TeacherPayload } from '../interface'
// import { resetStateT } from '../../../redux/reducer/Users/teacherReducer'

const useRegisterTeacher = (): { formik: FormikProps<TeacherPayload>
  initialValues: TeacherPayload
  validationSchema: Yup.ObjectSchema<any>
  uploadFileA: (file: File, bucketName: string) => Promise<string>
  loading: boolean
  loadingA: boolean
  error: any
  success: boolean
} => {
  const { loading: loadingA, error, success } = useAppSelector(state => state.teachersReducer)
  const dispatch = useAppDispatch()
  const { uploadFile } = useFirebase()
  const [loading, setLoading] = useState(false)

  const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    lastName: Yup.string().required('El apellido es obligatorio'),
    email: Yup.string().email('Correo electrónico no válido').required('El correo es obligatorio'),
    password: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('La contraseña es obligatoria'),
    phone: Yup.string().required('El teléfono es obligatorio'),
    address: Yup.string().required('El domicilio es obligatorio'),
    assignedGroupId: Yup.string().required('El grupo asignado es obligatorio'),
    birthdate: Yup.date().required('La fecha de nacimiento es obligatoria').max(new Date(), 'La fecha de nacimiento no puede ser mayor a la fecha actual'),
    curp: Yup.string().required('El CURP es obligatorio'),
    rfc: Yup.string().required('El RFC es obligatorio'),
    nss: Yup.string().required('El NSS es obligatorio'),
    dni: Yup.mixed(),
    cedula: Yup.mixed()
  })

  const initialValues: TeacherPayload = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    address: '',
    assignedGroupId: '',
    dni: undefined,
    cedula: undefined,
    birthdate: '',
    curp: '',
    rfc: '',
    nss: ''
  }

  const formik = useFormik<TeacherPayload>({
    initialValues,
    validationSchema,
    onSubmit: async (values: TeacherPayload, { resetForm }) => {
      const newPayload = { ...values }
      void dispatch(createTeacher({ ...newPayload }))
      if (success) {
        resetForm()
      }
    }
  })

  const uploadFileA = async (file: File, bucketName: string): Promise<string> => {
    setLoading(true)
    try {
      const uploadTask = await uploadFile(file, bucketName)
      return uploadTask
    } catch (error) {
      console.error('Image upload failed:', error)
      return ''
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void dispatch(getOnlyGrp())
  }, [])

  useEffect(() => {
    console.log(formik.errors)
  }, [formik.errors])

  return { formik, initialValues, validationSchema, uploadFileA, loading, loadingA, error, success }
}

export default useRegisterTeacher
