// NotFound.js

import React from 'react'
import { styled } from '@mui/system'
import { Typography, Container, Box } from '@mui/material'

// Estilos utilizando styled-components
const NotFoundContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const SadFace = styled('img')`
  width: 150px;
  height: 150px;
  margin-right: 16px;
`

const NotFound = (): JSX.Element => {
  return (
    <NotFoundContainer>
      <Box>
        <SadFace src="/sad-face.svg" alt="Sad Face" />
      </Box>
      <Box>
        <Typography variant="h4" gutterBottom>
          404 - Página no encontrada
        </Typography>
        <Typography variant="subtitle1">
          Parece que te has perdido. ¡Vuelve al camino correcto!
        </Typography>
      </Box>
    </NotFoundContainer>
  )
}

export default NotFound
