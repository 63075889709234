import { Input, FormControl } from '@mui/joy'
import { styled } from '@mui/material'

interface InputProps {
  toUpperCase?: boolean
}

export const InputUi = styled(Input)<InputProps>(({ toUpperCase }) => ({
  slotProps: {
    input: {
      style: {
        textTransform: toUpperCase ? 'uppercase' : 'none'
      }
    }
  }
}))

export const FormControlUi = styled(FormControl)(({ theme }) => ({
  '& .MuiInput-root': {
    padding: '0.8rem',
    borderRadius: '18px',
    backgroundColor: 'rgba(176, 186, 195, 0.4)',
    border: 'rgba(176, 186, 195, 0.4)',
    '&:hover': {
      backgroundColor: 'rgba(176, 186, 195, 0.6)'
    },
    '&:focus': {
      backgroundColor: 'rgba(176, 186, 195, 0.6)'
    }
  }
}))
