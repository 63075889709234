import { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  Divider
} from '@mui/material'
import FileOpenIcon from '@mui/icons-material/FileOpen'

interface Payment {
  date: string
  file: string
  _id: string
}

type GroupedPayments = Record<string, Payment[]>

const PaymentHistory = ({ historyPayments }: { historyPayments: Payment[] }): JSX.Element => {
  const [activeYear, setActiveYear] = useState<string | null>(null)
  const yearRefs = useRef<Record<string, HTMLDivElement | null>>({})

  const groupedPayments: GroupedPayments = historyPayments.reduce<GroupedPayments>((acc, payment) => {
    const year = new Date(payment.date).getFullYear().toString()
    if (!acc[year]) {
      acc[year] = []
    }
    acc[year].push(payment)
    return acc
  }, {})

  const sortedYears = Object.keys(groupedPayments).sort((a, b) => parseInt(b) - parseInt(a))

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveYear(entry.target.id)
          }
        })
      },
      { threshold: 0.5 }
    )

    sortedYears.forEach((year) => {
      const ref = yearRefs.current[year]
      if (ref) observer.observe(ref)
    })

    return () => { observer.disconnect() }
  }, [sortedYears])

  return (
    <Card sx={{ mx: 'auto' }}>
      {historyPayments && historyPayments.length === 0 && (
        <CardContent>
          <Typography variant="body1" align="center">
            No hay pagos registrados
          </Typography>
        </CardContent>
      )}
      <CardContent sx={{ p: 0 }}>
        <Box sx={{ maxHeight: 500, overflowY: 'auto', position: 'relative' }}>
          {sortedYears.map((year) => (
            <div key={year} ref={(el) => (yearRefs.current[year] = el)} id={year}>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 10,
                  bgcolor: 'background.paper',
                  p: 2,
                  fontWeight: 'bold',
                  color: activeYear === year ? 'primary.main' : 'text.secondary'
                }}
              >
                {year}
              </Box>
              {groupedPayments[year].map((payment) => (
                <Box key={payment._id} sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2">
                      {moment(payment.date).format('MMM D, YYYY')}
                    </Typography>
                    <IconButton
                      href={payment.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                    >
                      <FileOpenIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </div>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

export default PaymentHistory
