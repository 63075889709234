/* eslint-disable @typescript-eslint/no-misused-promises */
import { uploadBytesResumable, ref, getDownloadURL, deleteObject, type UploadTask } from 'firebase/storage'
import { storageRef } from '../../utils'
import { useEffect, useState } from 'react'

interface IFirebase {
  uploadImage: (file: File) => Promise<void>
  uploadFile: (file: File, bucketName: string) => Promise<string>
  uploadResume: UploadProgress
  status: string
  deleteFile: (bucketName: string) => Promise<{ success: boolean }>
}

interface UploadProgress {
  progress: number
}

const useFirebase = (): IFirebase => {
  const [uploadResume, setUploadResume] = useState<UploadProgress>({ progress: 0 })
  const [status, setStatus] = useState('')

  const handleUploadStateChange = async (uploadTask: UploadTask): Promise<string> =>
    await new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setUploadResume({ progress })

          switch (snapshot.state) {
            case 'paused':
              console.log('Upload paused')
              break
            case 'running':
              console.log('Upload in progress')
              break
            case 'success':
              console.log('Upload success')
              setStatus('success')
              break
          }
        },
        (error) => { reject(error) },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            resolve(downloadURL)
          } catch (error) {
            reject(error)
          }
        }
      )
    })

  const uploadImage = async (file: File): Promise<void> => {
    try {
      await uploadBytesResumable(storageRef, file)
    } catch (error) {
      console.error('Image upload failed:', error)
    }
  }

  const uploadFile = async (file: File, bucketName: string): Promise<string> => {
    const storageFilesRef = ref(storageRef, bucketName)
    const uploadTask = uploadBytesResumable(storageFilesRef, file)
    return await handleUploadStateChange(uploadTask)
  }

  const deleteFile = async (bucketName: string): Promise<{ success: boolean }> => {
    const storageFilesRef = ref(storageRef, bucketName)
    try {
      await deleteObject(storageFilesRef)
      return { success: true }
    } catch (error) {
      return { success: false }
    }
  }

  useEffect(() => {
    return () => {
      setUploadResume({ progress: 0 })
      setStatus('')
    }
  }, [])

  return { uploadImage, uploadFile, uploadResume, status, deleteFile }
}

export default useFirebase
