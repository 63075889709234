import { Box, Typography, Paper } from '@mui/material'
import styled from 'styled-components'
import { ViewStack } from '../../components'

const SectionTitle = styled(Typography)`
  font-size: 18px;
  margin-bottom: 10px;
`

const PoliticsandTerms = (): JSX.Element => {
  return (
    <ViewStack sx={{ padding: '0 12px', flexDirection: 'column', height: '100%!important' }} alignItems="center">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
          width: '100%',
          flexWrap: { xs: 'wrap' }
        }}
      >
        <Paper sx={{ padding: '20px', width: '100%', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}>
          <SectionTitle variant="h2">Aviso de Privacidad</SectionTitle>
          <Typography paragraph>
            En <strong>Colegio Miramar Bilingual Education</strong> estamos comprometidos con la protección de la privacidad de los usuarios de nuestra aplicación móvil. A continuación, explicamos cómo recopilamos, utilizamos y protegemos los datos personales.
          </Typography>
          <Typography variant="h6">1. Datos Personales Recopilados</Typography>
          <Typography paragraph>
            Datos generales: Recopilamos el nombre del estudiante, nombre de los padres, dirección, correo electrónico e imagen del alumno. Datos sensibles: También recopilamos información médica del alumno, la cual se considera como datos sensibles según la legislación aplicable.
          </Typography>
          <Typography variant="h6">2. Uso de los Datos</Typography>
          <Typography paragraph>
            La información médica se utiliza exclusivamente para garantizar la seguridad y bienestar del alumno en el entorno escolar, evitando cualquier incidente relacionado con su salud. Los datos del alumno son utilizados en la plataforma para la gestión de actividades escolares, como tareas y calificaciones. La información de contacto de los padres se usará exclusivamente para comunicaciones oficiales del colegio y no será compartida con terceros sin consentimiento explícito, salvo obligación legal.
          </Typography>
          <Typography variant="h6">3. Seguridad de los Datos</Typography>
          <Typography paragraph>
            Aplicamos encriptación y otras tecnologías de protección para garantizar la seguridad de los datos almacenados en nuestra base de datos. En caso de detectarse algún incidente de seguridad que afecte los datos personales, notificaremos a los padres mediante notificaciones push o directamente por correo electrónico.
          </Typography>
          <Typography variant="h6">4. Derechos de los Usuarios</Typography>
          <Typography paragraph>
            Los usuarios tienen el derecho de acceder y modificar sus datos personales a través de la aplicación en cualquier momento. Si los usuarios desean darse de baja de la plataforma, pueden hacerlo contactando con la administración escolar, y se procederá con la eliminación de sus datos, de acuerdo con nuestras políticas de retención.
          </Typography>
          <Typography variant="h6">5. Conservación y Eliminación de Datos</Typography>
          <Typography paragraph>
            Los datos personales se conservarán mientras el alumno esté inscrito en <strong>Colegio Miramar</strong>. Una vez que el alumno ya no esté inscrito, los datos serán eliminados de forma segura de nuestra base de datos.
          </Typography>
          <SectionTitle variant="h2">Términos y Condiciones</SectionTitle>
          <Typography variant="h6">1. Acceso a la Aplicación</Typography>
          <Typography paragraph>
            Solo los padres o tutores legales de los estudiantes tendrán acceso a la aplicación. Para acceder a la app, los usuarios deben autenticarse mediante un correo electrónico válido y una contraseña segura, los cuales deberán ser confidenciales.
          </Typography>
          <Typography variant="h6">2. Pagos y Recargos</Typography>
          <Typography paragraph>
            Los recargos por retrasos en los pagos de las mensualidades u otros servicios se notificarán a través de la aplicación. Los métodos de pago permitidos incluyen transferencias bancarias, pagos en efectivo o mediante tarjeta de crédito/débito en las instalaciones del colegio. Para la emisión de facturas, deberá realizarse una solicitud previa.
          </Typography>
          <Typography variant="h6">3. Limitaciones de Responsabilidad</Typography>
          <Typography paragraph>
            <strong>Colegio Miramar Bilingual Education</strong> no se hace responsable por errores técnicos en la aplicación o por inexactitudes en la información presentada. En caso de errores graves, se notificará a los usuarios mediante correo electrónico o notificaciones push.
          </Typography>
          <Typography variant="h6">4. Actualización de los Términos</Typography>
          <Typography paragraph>
            Cualquier modificación en los términos y condiciones será notificada a los usuarios mediante correo electrónico. Es responsabilidad de los usuarios mantenerse informados sobre estas actualizaciones.
          </Typography>
          <Typography variant="h6">5. Uso Responsable de la Aplicación</Typography>
          <Typography paragraph>
            Los padres son responsables de la confidencialidad de sus credenciales de acceso y de cualquier uso indebido por parte de terceros a los que hayan otorgado acceso. El colegio se reserva el derecho de evaluar cualquier incumplimiento de estos términos y aplicar las medidas correctivas pertinentes según cada caso.
          </Typography>
        </Paper>
      </Box>
    </ViewStack>
  )
}

export default PoliticsandTerms
