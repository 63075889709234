import React from 'react'
import { Card, CardContent, Avatar, Typography, LinearProgress, IconButton, Box } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

interface TeamMember {
  name: string
  email: string
  progress: number
  avatarUrl: string
  role: string
}

interface TeamMemberCardProps {
  member: TeamMember
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ member }) => (
  <Card sx={{ maxWidth: 345, m: 2 }}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
        <Avatar src={member.avatarUrl} sx={{ width: 56, height: 56 }} />
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Typography variant="h6" component="div">
        {member.name}
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {member.email}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={member.progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(member.progress)}%`}</Typography>
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        {member.role}
      </Typography>
    </CardContent>
  </Card>
)

interface TeamMemberCardsProps {
  members?: TeamMember[]
}

export default TeamMemberCard
export type { TeamMemberCardsProps }
