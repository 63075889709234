import { useEffect, useState } from 'react'

interface Student {
  name: string
  lastName: string
  curp: string
  group_id: string | null
  paymentStatus: string
  paymentAmount: number
  discountAmount: number
  totalAmount: number
  _id: string
}

interface User {
  _id: string
  group_id: string | null
  students: Student[]
  name: string
  lastName: string
  curp: string
  paymentStatus: string
  paymentAmount: number
  discountAmount: number
  totalAmount: number
  mainId: string
}

export const useSeparateStudentsByGroup = (users: User[] | null): User[] => {
  const [formattedStudents, setFormattedStudents] = useState<User[]>([])

  useEffect(() => {
    if (!users) return

    const separatedStudents: User[] = []

    users.forEach((user: User) => {
      if (user.group_id === null && user.students && user.students.length > 0) {
        user.students.forEach((student: Student) => {
          separatedStudents.push({
            ...user,
            name: student.name,
            lastName: student.lastName,
            curp: student.curp,
            group_id: student.group_id,
            paymentStatus: student.paymentStatus,
            paymentAmount: student.paymentAmount,
            discountAmount: student.discountAmount,
            totalAmount: student.totalAmount,
            mainId: student._id,
            students: []
          })
        })
      } else {
        separatedStudents.push(user)
      }
    })

    setFormattedStudents(separatedStudents)
  }, [users && JSON.stringify(users)])

  return formattedStudents
}
