import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

interface Props {
  borderRadius?: boolean
}

const SkeletonColor = ({ borderRadius }: Props): JSX.Element => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        bgcolor: theme.palette.common.black,
        p: 8,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Skeleton
        sx={{ bgcolor: theme.palette.common.white, borderRadius: 100 }}
        variant="rectangular"
        width={210}
        height={borderRadius ? 210 : 118}
      />
    </Box>
  )
}

export default SkeletonColor
