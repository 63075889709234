import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { PLANNINGS } from '../../constants'

const initialState = {
  loading: false,
  error: '',
  data: null,
  success: false
}

const getPlanningsReducer = createSlice({
  name: 'getPlanningsReducer',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(PLANNINGS.FETCH_PLANNING, state => {
        state.loading = true
      })
      .addCase(PLANNINGS.FETCH_PLANNINGS_SUCCESS, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.success = true
        state.data = action.payload
      })
      .addCase(PLANNINGS.FETCH_PLANNINGS_ERROR, (state, action: PayloadAction<string>) => {
        state.loading = false
        state.error = action.payload
      })
  },
  reducers: {
    resetStateBooleans: state => {
      state.loading = false
      state.error = ''
      state.success = false
    },
    resetStateData: state => initialState
  }
})

export const { resetStateBooleans, resetStateData } = getPlanningsReducer.actions
export default getPlanningsReducer.reducer
