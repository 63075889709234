import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box
} from '@mui/material'

const TeacherDashboard = (): JSX.Element => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard del Maestro
      </Typography>
      <HomeSection />
    </Box>
  )
}

const HomeSection = (): JSX.Element => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Resumen de Actividades" subheader="Alumnos que han subido sus actividades" />
          <CardContent>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>Henry Paulists</li>
              <li>Evan Jefferson</li>
              <li>Marh Tomos</li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Próximos Eventos" />
          <CardContent>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Junta de profesores - 15 de Mayo - hora 10:00</li>
              <li>Entrega de calificaciones - 20 de Mayo - hora 12:00</li>
              <li>Día del Maestro - 15 de Mayo - hora 10:00</li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default TeacherDashboard
