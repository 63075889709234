import { styled, Paper } from '@mui/material'

const DragAndDrop = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027'
  }),
  position: 'relative',
  height: 200,
  width: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(1),
  border: '2px dashed #3f51b5',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  },
  transition: 'background-color 0.3s',
  borderRadius: 10

}))

export { DragAndDrop }
