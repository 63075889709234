import Popover from '@mui/material/Popover'
import { IconButton } from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { useState } from 'react'

interface PropsPopover {
  children: React.ReactNode
  icon?: React.ReactNode
}

const BasicPopover = ({ children, icon }: PropsPopover): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        {icon ?? <MoreVertRoundedIcon color="primary" />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {children}
      </Popover>
    </div>
  )
}

export default BasicPopover
