import { useEffect, useState } from 'react'
import { resetStateData } from '../../redux/reducer/MessageSnackbar/messageSnackbarReducer'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import Snackbar, { type SnackbarOrigin } from '@mui/material/Snackbar'

interface State extends SnackbarOrigin {
  open: boolean
}

const CustomSnackbar = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { message } = useAppSelector(state => state.messageSnackbarReducer)
  const [state, setState] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  })
  const { vertical, horizontal, open } = state

  const handleClose = (): void => {
    setState({ ...state, open: false })
    setTimeout(() => {
      dispatch(resetStateData())
    }, 1000)
  }

  useEffect(() => {
    if (message) {
      setState({ ...state, open: true })
    }
  }, [message])

  useEffect(() => {
    setTimeout(() => {
      handleClose()
    }, 2000)
  }, [])

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      message={message}
      key={vertical + horizontal}
    />
  )
}

export default CustomSnackbar
