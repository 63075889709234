import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { getEvents, createEvent } from '../../../api/services'
import { setMessage } from '../../../redux/reducer/MessageSnackbar/messageSnackbarReducer'
import { useAppDispatch } from '../../../redux/hook'
import useFirebase from 'hooks/FirebseHooks/useFirebase'
import { useFormik } from 'formik'

interface HomePropsHook {
  events: { success: boolean, data: any[] }
  createAllEvent: (payload: any) => Promise<any>
  createEventsRes: { success: boolean, data: any[], error: string }
  loadingEvent: boolean
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void
  errorFiles: boolean
  handleSubmitFileFirebase: () => Promise<void>
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  uploadResume: any
  loading: boolean
  successUpload: boolean
  formik: any
  status: string
  deleteFile: (bucketName: string) => Promise<{ success: boolean }>
  file: File[]
  handleRemoveFile: (index: number, bucketName?: string) => Promise<void>
}

const useHome = (): HomePropsHook => {
  const dispatch = useAppDispatch()
  const [file, setFile] = useState<File[]>([])
  const [errorFiles, setErrorFiles] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successUpload, setSuccessUpload] = useState(false)
  const { uploadFile, uploadResume, status, deleteFile } = useFirebase()

  const validationSchema = Yup.object({
    title: Yup.string().required('El título es requerido'),
    place: Yup.string().required('El lugar es requerido'),
    file: Yup.array().min(1, 'Debe subir al menos un archivo'),
    description: Yup.string().required('La descripción es requerida').max(150, 'La descripción no debe ser mayor a 150 caracteres'),
    date: Yup.date().required('La fecha es requerida'),
    group_id: Yup.string().required('El grupo es requerido'),
    time: Yup.string().required('La hora es requerida')
  })

  const formik = useFormik({
    initialValues: {
      title: '',
      date: '',
      description: '',
      group_id: '',
      time: '',
      file: [],
      place: ''
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values)
      const dataStorage = JSON.parse(localStorage.getItem('dataUser') as string)
      const payload = {
        title: values.title,
        date: values.date,
        description: values.description,
        group_id: values.group_id === 'all' ? '' : values.group_id,
        createdBy_id: dataStorage.user._id,
        file: formik.values.file,
        time: values.time,
        place: values.place
      }
      console.log(payload)
      void createAllEvent(payload)
    }
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files[0].size > 3145728) {
      setErrorFiles(true)
      return
    } else {
      setErrorFiles(false)
    }

    if (event.target.files) {
      const filesArray = Array.from(event.target.files)
      setFile([...file, ...filesArray])
    }
  }

  const [events, setEvents] = useState({
    success: false,
    data: []
  })
  const [createEventsRes, setCreateEvents] = useState({
    success: false,
    data: [],
    error: ''
  })
  const [loadingEvent, setLoadingEvent] = useState(false)

  const getAllEvents = async (): Promise<void> => {
    const event = await getEvents()
    try {
      setEvents({
        success: true,
        data: event.data
      })
    } catch (error) {
      setEvents({
        success: false,
        data: []
      })
    }
  }

  const createAllEvent = async (payload: any): Promise<any> => {
    setLoadingEvent(true)
    try {
      const event = await createEvent(payload)
      setCreateEvents({
        success: true,
        data: event.data,
        error: ''
      })
      dispatch(setMessage({ message: 'Evento creado correctamente', type: 'success' }))
    } catch (error: any) {
      console.log('Error', error.response.data.message)
      dispatch(setMessage({ message: error.response.data.message, type: 'success' }))
      setCreateEvents({
        success: false,
        data: [],
        error: error as string
      })
    } finally {
      setLoadingEvent(false)
    }
  }

  const handleRemoveFile = async (index: number, bucketName?: string): Promise<void> => {
    if (!successUpload) {
      const newFiles = file.filter((_, i) => i !== index)
      setFile(newFiles)
    } else {
      if (bucketName) {
        const res: { success: boolean } = await deleteFile(bucketName)
        if (res.success) {
          const newFiles = file.filter((_, i) => i !== index)
          setFile(newFiles)
        }
      }
    }
  }

  const handleSubmitFileFirebase = async (): Promise<void> => {
    if (file.length > 0) {
      setLoading(true)
      const url = await Promise.all(file.map(async (f: File) => {
        return await uploadFile(f, `events_shool/${formik.values.title.trim().toLowerCase().replace(/\s/g, '') && 'events_shool'}/${f.name.trim().toLowerCase().replace(/\s/g, '')}`)
      }))

      try {
        void formik.setFieldValue('file', url)
        setSuccessUpload(true)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    const droppedFiles = Array.from(e.dataTransfer.files)
    console.log(droppedFiles)
  }

  useEffect(() => {
    void getAllEvents()
  }, [])

  return {
    events,
    createAllEvent,
    createEventsRes,
    loadingEvent,
    handleDrop,
    errorFiles,
    handleSubmitFileFirebase,
    handleFileChange,
    uploadResume,
    loading,
    successUpload,
    formik,
    status,
    deleteFile,
    file,
    handleRemoveFile
  }
}

export default useHome
