import { GROUPS } from '../../constants'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IState {
  loading: boolean
  error: any
  data: any[] | null
  success: boolean
}

const initialState: IState = {
  loading: false,
  error: null,
  data: [],
  success: false
}

interface Action {
  type: string
  payload: any[]
}

const createStudentGrpsReducer = createSlice({
  name: 'createStudentGrpsReducer',
  initialState,
  extraReducers: builder => {
    builder.addCase(GROUPS.GROUPS_CREATE_STUDENT_LOADING, (state) => {
      state.loading = true
    })
      .addCase(GROUPS.GROUPS_CREATE_STUDENT_SUCCESS, (state, action: PayloadAction<Action>) => {
        state.loading = false
        state.success = true
        state.data = action.payload as unknown as any[]
      })
      .addCase(GROUPS.GROUPS_CREATE_STUDENT_ERROR, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      }
      )
  },
  reducers: {
    resetStdGroups: () => initialState
  }
})

export const { resetStdGroups } = createStudentGrpsReducer.actions

export default createStudentGrpsReducer.reducer
