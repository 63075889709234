import { GROUPS } from '../../constants'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IState {
  loading: boolean
  error: null | string
  data: any[] | null
  success: boolean
}

const initialState: IState = {
  loading: false,
  error: null,
  data: [],
  success: false
}

interface GroupsAction {
  type: string
  payload: any[]
}

const createGrpsReducer = createSlice({
  name: 'createGrpsReducer',
  initialState,
  extraReducers: builder => {
    builder.addCase(GROUPS.GROUPS_CREATE_LOADING, (state) => {
      state.loading = true
    })
      .addCase(GROUPS.GROUPS_CREATE_SUCCESS, (state, action: PayloadAction<GroupsAction>) => {
        state.loading = false
        state.success = true
        state.data = action.payload as unknown as any[]
      })
      .addCase(GROUPS.GROUPS_CREATE_ERROR, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      }
      )
  },
  reducers: {
    resetGroups: () => initialState
  }
})

export const { resetGroups } = createGrpsReducer.actions

export default createGrpsReducer.reducer
