import { Grid, Typography } from '@mui/material'
import { FormField } from '../styles/styles'
import { InputC } from '../../../components'
import { type FormikValues } from 'formik'

type DinamicsInterfaceParents = Record<string, { label: string, placeholder: string }>

const parentsFields: DinamicsInterfaceParents = {
  name: { label: 'Nombre completo', placeholder: 'Ingresa nombre y apellidos' },
  dni: { label: 'INE', placeholder: 'Ingresa clave de elector' },
  phone: { label: 'Teléfono', placeholder: 'Ingresa el teléfono' },
  address: { label: 'Dirección', placeholder: 'Ingresa la dirección' },
  zipCode: { label: 'Código Postal', placeholder: 'Ingresa el código postal' },
  work: { label: 'Trabajo', placeholder: 'Ingresa el trabajo' },
  email: { label: 'Correo Electrónico', placeholder: 'Ingresa el correo electrónico' },
  phoneWork: { label: 'Teléfono del Trabajo', placeholder: 'Ingresa el teléfono del trabajo' },
  civilStatus: { label: 'Estado Civil', placeholder: 'Ingresa el estado civil' }
}

const ParentFormSection = ({ parentKey, parentTitle, formik }: any): JSX.Element => {
  const parentValues = formik.values.parents[parentKey] || {}

  return (
    <>
      <Typography variant="h6" component="h6" mb={5}>
        {parentTitle}
      </Typography>
      <FormField>
        <Grid container spacing={2}>
          {Object.keys(parentsFields).map((key) => (
            <Grid xs={12} sm={4} key={key}>
              <InputC
                colorLabel='#141415'
                name={`parents.${parentKey}.${key}`}
                label={parentsFields[key].label}
                placeholder={parentsFields[key].placeholder}
                value={parentValues[key] || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.parents?.[parentKey]?.[key] &&
                  formik.errors.parents?.[parentKey]?.[key]
                }
              />
            </Grid>
          ))}
        </Grid>
      </FormField>
    </>
  )
}

const ParentsForm = ({ formik }: FormikValues): JSX.Element => {
  return (
    <>
      <ParentFormSection parentKey="father" parentTitle="Datos del padre" formik={formik} />
    </>
  )
}

export default ParentsForm
