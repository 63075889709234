import { type User } from 'interface'
import { USERS } from '../../constants'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IState {
  loading: boolean
  error: null | string
  data: User[] | null
  success: boolean
}

const initialState: IState = {
  loading: false,
  error: null,
  data: [],
  success: false
}

interface UsersAction {
  type: string
  payload: User[]
}

const users = createSlice({
  name: 'usersReducer',
  initialState,
  extraReducers: builder => {
    builder.addCase(USERS.USERS_LOADING, (state) => {
      state.loading = true
    })
      .addCase(USERS.USERS_SUCCESS, (state, action: PayloadAction<UsersAction>) => {
        state.loading = false
        state.success = true
        state.data = action.payload as unknown as User[]
      })
      .addCase(USERS.USERS_ERROR, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      }
      )
  },
  reducers: {
    resetUsers: () => initialState
  }
})

export const { resetUsers } = users.actions

export default users.reducer
