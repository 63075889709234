const USERS = {
  USERS_LOADING: 'USERS_LOADING',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_ERROR: 'USERS_ERROR',
  USERS_RESET: 'USERS_RESET',
  LOGOUT: 'LOGOUT'

}

const STUDENT = {
  STUDENT_LOADING: 'STUDENT_LOADING',
  STUDENT_SUCCESS: 'STUDENT_SUCCESS',
  STUDENT_ERROR: 'STUDENT_ERROR'
}
const STUDENT_FORM = {
  STUDENT_LOADING_FORM: 'STUDENT_LOADING_FORM',
  STUDENT_SUCCESS_FORM: 'STUDENT_SUCCESS_FORM',
  STUDENT_ERROR_FORM: 'STUDENT_ERROR_FORM'
}

const TEACHER = {
  TEACHER_LOADING: 'TEACHER_LOADING',
  TEACHER_SUCCESS: 'TEACHER_SUCCESS',
  TEACHER_ERROR: 'TEACHER_ERROR',
  TEACHER_LOADING_A: 'TEACHER_LOADING_A',
  TEACHER_SUCCESS_A: 'TEACHER_SUCCESS_A',
  TEACHER_ERROR_A: 'TEACHER_ERROR_A'
}

const STUDENT_UPDATE = {
  STUDENT_LOADING_UPDATE: 'STUDENT_LOADING_UPDATE',
  STUDENT_SUCCESS_UPDATE: 'STUDENT_SUCCESS_UPDATE',
  STUDENT_ERROR_UPDATE: 'STUDENT_ERROR_UPDATE'
}

const STUDENT_ALL = {
  STUDENT_ALL_LOADING: 'STUDENT_ALL_LOADING',
  STUDENT_ALL_SUCCESS: 'STUDENT_ALL_SUCCESS',
  STUDENT_ALL_ERROR: 'STUDENT_ALL_ERROR'
}

const PAYMENT_PERCENTAGE = {
  PAYMENT_PERCENTAGE_LOADING: 'PAYMENT_PERCENTAGE_LOADING',
  PAYMENT_PERCENTAGE_SUCCESS: 'PAYMENT_PERCENTAGE_SUCCESS',
  PAYMENT_PERCENTAGE_ERROR: 'PAYMENT_PERCENTAGE_ERROR'
}

export { USERS, STUDENT, TEACHER, STUDENT_FORM, STUDENT_UPDATE, STUDENT_ALL, PAYMENT_PERCENTAGE }
