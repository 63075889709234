import { type ThemeOptions, type PaletteMode } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: blueGrey,
          common: {
            black: grey[500],
            white: grey[200]
          },
          secondary: grey,
          divider: grey[300],
          text: {
            primary: grey[900],
            secondary: grey[800]
          }
        }
      : {
          primary: grey,
          secondary: blueGrey,
          divider: grey[700],
          background: {
            default: grey[900],
            paper: grey[900]
          },
          text: {
            primary: '#fff',
            secondary: grey[500]
          }
        })
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 801,
      lg: 980,
      xl: 1440
    }
  }
})
