import { type Dispatch } from 'redux'
import { getAllUsers, getStudentId, updateStudentId, updateMultiStudentId } from '../../../api/services'
import { USERS, STUDENT, STUDENT_UPDATE } from '../../../redux/constants'
import { type AxiosResponse } from 'axios'
import { type UserStudent } from 'interface'
import { setMessage, setMessageError } from '../../reducer/MessageSnackbar/messageSnackbarReducer'
import { resetStudent } from '../../reducer/Users/updateStudentReducer'

export enum UserRole {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  DIRECTOR = 'DIRECTOR',
  ROOT = 'ROOT',

}
export const getUsers = (role: UserRole) => async (dispatch: any): Promise<any> => {
  dispatch({ type: USERS.USERS_LOADING })
  try {
    const res: AxiosResponse<any> = await getAllUsers(role)
    dispatch({ type: USERS.USERS_SUCCESS, payload: res.data.data })
  } catch (error) {
    dispatch({ type: USERS.USERS_ERROR, payload: error })
  }
}

export const getStudent = (id: string) => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: STUDENT.STUDENT_LOADING })
  const token = JSON.parse(localStorage.getItem('dataUser') as string).access_token
  try {
    const res: AxiosResponse<any> = await getStudentId(token, id)
    dispatch({ type: STUDENT.STUDENT_SUCCESS, payload: res.data.user })
  } catch (error: any) {
    dispatch({ type: STUDENT.STUDENT_ERROR, payload: error.message })
  }
}

export const updateStudent = (id: string | undefined, payload: UserStudent, errorMessage = 'Error al actualizar usuario', message = 'Se actualizó el usuario correctamente') => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: STUDENT_UPDATE.STUDENT_LOADING_UPDATE })
  try {
    const res: AxiosResponse<any> = await updateStudentId(id, payload)
    dispatch({ type: STUDENT_UPDATE.STUDENT_SUCCESS_UPDATE, payload: res.data.user })
    dispatch(setMessage({ message, type: 'success' }))
  } catch (error: any) {
    dispatch({ type: STUDENT_UPDATE.STUDENT_ERROR_UPDATE, payload: error.message })
    dispatch(setMessageError({ message: errorMessage, type: 'error' }))
  } finally {
    setTimeout(() => {
      dispatch(resetStudent())
    }, 1000)
  }
}
export const updateMultiStudent = (id: string | undefined, mainId: string | undefined, payload: UserStudent, errorMessage = 'Error al actualizar usuario', message = 'Se actualizó el usuario correctamente') => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: STUDENT_UPDATE.STUDENT_LOADING_UPDATE })
  try {
    const res: AxiosResponse<any> = await updateMultiStudentId(id, mainId, payload)
    dispatch({ type: STUDENT_UPDATE.STUDENT_SUCCESS_UPDATE, payload: res.data.user })
    dispatch(setMessage({ message, type: 'success' }))
  } catch (error: any) {
    dispatch({ type: STUDENT_UPDATE.STUDENT_ERROR_UPDATE, payload: error.message })
    dispatch(setMessageError({ message: errorMessage, type: 'error' }))
  } finally {
    setTimeout(() => {
      dispatch(resetStudent())
    }, 1000)
  }
}
// Error al ajustar la mensualidad, intente de nuevo o contacte al administrador
