import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { SNACKBAR } from '../../constants'

interface PropsState {
  loading: boolean
  error: string | null
  message: string | null
  success: boolean
  errorMessage: string | null
}

const initialState: PropsState = {
  loading: false,
  error: null,
  message: null,
  errorMessage: null,
  success: false
}

const messageSnackbarReducer = createSlice({
  name: 'messageSnackbarReducer',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(SNACKBAR.SNACKBAR_LOADING, state => {
        state.loading = true
      })
      .addCase(SNACKBAR.SNACKBAR_SUCCESS, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.success = true
        state.message = action.payload
      })
      .addCase(SNACKBAR.SNACKBAR_ERROR, (state, action: PayloadAction<string>) => {
        state.loading = false
        state.error = action.payload
      })
  },
  reducers: {
    resetStateBooleans: state => {
      state.loading = false
      state.error = null
      state.success = false
    },
    resetStateData: () => initialState,
    setMessage: (state, action: PayloadAction<{ message: string, type: string }>) => {
      state.message = action.payload.message
      state.success = action.payload.type === 'success'
    },
    setMessageError: (state, action: PayloadAction<{ message: string, type: string }>) => {
      state.errorMessage = action.payload.message
      state.success = action.payload.type === 'error'
    }
  }
})

export const { resetStateBooleans, resetStateData, setMessage, setMessageError } = messageSnackbarReducer.actions
export default messageSnackbarReducer.reducer

// dispatch(setMessage({ message: 'Mensualidad ajustada correctamente', type: 'success' }))
