import { useEffect } from 'react'
import { Analytics } from '@vercel/analytics/react'
import { ThemeProvider } from '@mui/material'
import { useDarkMode } from './hooks'
import { Main } from './Views'
import useAuth from './hooks/AuthHooks/useAuth'

const App = (): JSX.Element => {
  const { darkModeTheme } = useDarkMode()
  const { status, setStatus, data } = useAuth()

  useEffect(() => {
    const dataUsr = localStorage.getItem('dataUser')
    if (dataUsr) {
      setStatus(true)
    }
  }, [status, data])

  return (
    <ThemeProvider theme={darkModeTheme}>
      <Main />
      <Analytics />
    </ThemeProvider>
  )
}

export default App
