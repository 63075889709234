const AUTH = {
  AUTH_LOADING: 'AUTH_LOADING',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',
  AUTH_RESET: 'AUTH_RESET',
  LOGOUT: 'LOGOUT'

}

const GENERATE_LINK = {
  GENERATE_LINK_LOADING: 'GENERATE_LINK_LOADING',
  GENERATE_LINK_SUCCESS: 'GENERATE_LINK_SUCCESS',
  GENERATE_LINK_ERROR: 'GENERATE_LINK_ERROR',
  GENERATE_LINK_RESET: 'GENERATE_LINK_RESET'
}

export { AUTH, GENERATE_LINK }
