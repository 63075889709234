import styled from '@emotion/styled'
import Stepper from '@mui/material/Stepper'

const CustomStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    // borderColor: '#00b1e1'
  },
  '& .MuiStepLabel-root': {
    flexDirection: 'column'
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: '#1976d2'
  },
  '& .MuiStepLabel-labelContainer': {
    width: '150%'
  },
  '& .MuiStepLabel-label': {
    color: '#057b9b',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px'
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: '#000',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px'
  },
  '& .MuiStepLabel-label.MuiStepLabel-active': {
    color: '#00b1e1'
  },
  '& .MuiStepLabel-label.MuiStepLabel-completed': {
    color: '#00b1e1'
  }
}))

export { CustomStepper }

// backgroundColor: 'transparent',
// padding: '0',
// margin: '0',
// '& .MuiStepLabel-label': {
//   fontFamily: 'Poppins',
//   fontWeight: '500',
//   fontSize: '14px',
//   lineHeight: '21px',
//   color: '#141415'
// },
// '& .MuiStepLabel-active': {
//   color: '#00B1EA'
// },
// '& .MuiStepLabel-completed': {
//   color: '#00B1EA'
// },
// '& .MuiStepLabel-alternativeLabel': {
//   marginTop: '0'
// },
// '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
//   marginTop: '0'
// },
// '& .MuiStepLabel-labelContainer': {
//   '& .MuiStepIcon-root': {
//     color: '#00B1EA'
//   }
// }
