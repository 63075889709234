import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../redux/hook'
import { getGrpId, updateMultiStudent, updateStudent } from '../../redux/actions'
import { useAppDispatch } from '../../redux/tpye_hooks'
import { resetGroups } from '../../redux/reducer/Groups/groupsReducer'
import { type UserStudent } from 'interface'
import { resetStateData } from '../../redux/reducer/MessageSnackbar/messageSnackbarReducer'
import { type PaymentStatus } from 'Views/Students/styled/styles'

const filterStudents = (users: any, groupId: string): any => {
  const filteredStudents = users?.students_id?.map((student: any) => {
    if (student.group_id !== null) {
      return student
    }

    if (student.students && student.students.length > 0) {
      const filteredNestedStudents = student.students.filter((nestedStudent: any) => {
        return nestedStudent.group_id === groupId
      })

      if (filteredNestedStudents.length > 0) {
        return {
          ...student,
          students: filteredNestedStudents
        }
      }
    }

    return null
  }).filter(Boolean)

  return filteredStudents
}

const useGroup = (id: string | undefined): any => {
  const { data: users, loading } = useAppSelector((state: { groupReducer: any }) => state.groupReducer)
  const { success } = useAppSelector((state: { updateStudentReducer: any }) => state.updateStudentReducer)
  const dispatch = useAppDispatch()
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [student, setStudent] = useState({} as UserStudent)

  const totalAmount = (x: number, y: number): number => x - y

  const dataGroups = async (): Promise<void> => {
    try {
      await dispatch(getGrpId(id))
    } catch (error) {
      console.error('Error fetching group data:', error)
    }
  }

  useEffect(() => {
    if (id) {
      void dataGroups()
    }

    return () => {
      dispatch(resetGroups())
    }
  }, [id, dispatch, success])

  const filteredStudents = useMemo(() => filterStudents(users, id as string), [users, id])

  const editStudent = (student: UserStudent): void => {
    setStudent(student)
    setOpenPopoverId(null)
    setOpenModal(true)
  }

  const handleCloseModal = (id: string): void => {
    setOpenModal(false)
    dispatch(resetStateData())
  }

  const updatePaymentStatus = (paymentStatus: PaymentStatus, id: string, mainId: string): void => {
    const payloadActions = {
      errorMessage: 'Error al actualizar el estado de pago',
      message: 'Estado de pago actualizado correctamente'
    }

    if (mainId) {
      void dispatch(updateMultiStudent(id, mainId, { paymentStatus }, payloadActions.errorMessage, payloadActions.message))
      return
    }

    void dispatch(updateStudent(id, { paymentStatus }, payloadActions.errorMessage, payloadActions.message))
  }

  return {
    users: { ...users, students_id: filteredStudents },
    loading,
    totalAmount,
    openPopoverId,
    editStudent,
    openModal,
    handleCloseModal,
    student,
    open,
    updatePaymentStatus
  }
}

export default useGroup
