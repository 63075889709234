import React from 'react'
import { FormComp } from './styles/style'

interface Props {
  children: React.ReactNode
}

const FormC = ({ children }: Props): JSX.Element => {
  return (
    <FormComp>
        {children}
    </FormComp>
  )
}

export default FormC
