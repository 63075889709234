import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Paper,
  Tabs,
  Tab,
  TextField,
  Grid,
  Box,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import { Search as SearchIcon, Notifications as NotificationsIcon } from '@mui/icons-material'
import { styled, alpha } from '@mui/material/styles'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}))

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}))

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  date: Yup.string().required('Date is required'),
  subject: Yup.string().required('Subject is required'),
  objective: Yup.string().required('Objective is required'),
  materials: Yup.string().required('Materials are required'),
  procedures: Yup.string().required('Procedures are required')
})

const ClassRoomPlaner = (): JSX.Element => {
  const [value, setValue] = useState(0)
  const [listPlaner, setListPlaner] = useState<Array<{ title: string, description: string, date: string, startTime: string, endTime: string, roomNumber: string, gradeLevel: string, subject: string, standard: string, objective: string, materials: string, procedures: string }>>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      date: '',
      startTime: '',
      endTime: '',
      roomNumber: '',
      gradeLevel: '',
      subject: '',
      standard: '',
      objective: '',
      materials: '',
      procedures: ''
    },
    validationSchema,
    onSubmit: (values) => {
      setListPlaner([...listPlaner, values])
      formik.resetForm()
    }
  })

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Planeaciones
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <IconButton size="large" aria-label="show notifications" color="inherit">
            <NotificationsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Planeacion" />
          <Tab label="Mis Planeaciones" />
        </Tabs>
      </Paper>
      <Box sx={{ p: 3 }}>
        {value === 0
          ? (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Lesson Title"
                    variant="outlined"
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Lesson Description"
                    variant="outlined"
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    multiline
                    rows={4}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Date"
                    variant="outlined"
                    id="date"
                    name="date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Subject"
                    variant="outlined"
                    id="subject"
                    name="subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Objective"
                    variant="outlined"
                    id="objective"
                    name="objective"
                    multiline
                    rows={4}
                    value={formik.values.objective}
                    onChange={formik.handleChange}
                    error={formik.touched.objective && Boolean(formik.errors.objective)}
                    helperText={formik.touched.objective && formik.errors.objective}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Materials"
                    variant="outlined"
                    id="materials"
                    name="materials"
                    multiline
                    rows={4}
                    value={formik.values.materials}
                    onChange={formik.handleChange}
                    error={formik.touched.materials && Boolean(formik.errors.materials)}
                    helperText={formik.touched.materials && formik.errors.materials}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Procedures"
                    variant="outlined"
                    id="procedures"
                    name="procedures"
                    multiline
                    rows={4}
                    value={formik.values.procedures}
                    onChange={formik.handleChange}
                    error={formik.touched.procedures && Boolean(formik.errors.procedures)}
                    helperText={formik.touched.procedures && formik.errors.procedures}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Create Plan
                  </Button>
                </Grid>
              </Grid>
            </form>
            )
          : (
            <Box>
              {listPlaner.length > 0
                ? (
                  <List>
                    {listPlaner.map((plan, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={plan.title}
                          secondary={`Date: ${plan.date}, Subject: ${plan.subject}, Objective: ${plan.objective}, Description: ${plan.description}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                  )
                : (
                  <Typography>No lesson plans created yet.</Typography>
                  )}
            </Box>
            )}
      </Box>
    </Box>
  )
}

export default ClassRoomPlaner
