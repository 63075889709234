import { createTheme, type Theme, type PaletteMode } from '@mui/material'
import { useEffect } from 'react'
import { type DarkMode } from '../../interface'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { setDarkMode } from '../../redux/reducer'
import { getDesignTokens } from '../../theme/theme'

export const useDarkMode = (): DarkMode => {
  const { isDarkMode: dark } = useAppSelector(state => state.darkMode)
  const darkModeTheme: Theme = createTheme(getDesignTokens(dark))
  const dispatch = useAppDispatch()

  const toggleSwitchDark = (): void => {
    const darkMode = dark === 'dark' ? 'light' : 'dark'
    dispatch(setDarkMode(darkMode))
  }

  useEffect(() => {
    const darkMode = async (): Promise<void> => {
      const isDarkMode = localStorage.getItem('isDarkMode') as PaletteMode
      console.log(isDarkMode)
      if (isDarkMode) {
        dispatch(setDarkMode(isDarkMode))
      } else {
        dispatch(setDarkMode('light'))
      }
    }
    void darkMode()
  }, [dispatch])

  return { dark, darkModeTheme, toggleSwitchDark }
}
