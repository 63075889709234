import * as Yup from 'yup'
import { type ObjectSchema } from 'yup'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { signIn } from '../../../redux/actions'
import { useEffect } from 'react'

const useForm = (): { initialValues: typeof initialValues, validationSchema: ObjectSchema<typeof initialValues>, formik: any, loading: boolean, success: boolean } => {
  const dispatch = useAppDispatch()

  const { loading, success, error } = useAppSelector((state) => state.authReducer)
  const validationSchema: ObjectSchema<typeof initialValues> = Yup.object().shape({
    email: Yup.string().required('E-mail obrigatório').email('E-mail inválido'),
    password: Yup.string()
      .required('El password es obligatorio')
      .min(3, 'No mínimo 3 dígitos')
  })

  const initialValues = {
    email: '',
    password: ''
  }

  useEffect(() => {
    if (loading) {
      void toast.loading('Cargando...')
    } else {
      toast.dismiss()
    }
    if (success) {
      void toast.success('Inicio de sesión exitoso')
    }
    if (error) {
      void toast.error(error)
      setTimeout(() => {
        toast.dismiss()
      }, 3000)
    }
  }, [loading, success, error])

  const onSubmit = (values: typeof initialValues): void => {
    void dispatch(signIn(values))
  }

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: typeof initialValues) => {
      onSubmit({ email: values.email.trim().toLowerCase(), password: values.password })
    }
  })

  return {
    initialValues,
    validationSchema,
    formik,
    loading,
    success
  }
}

export default useForm
