import { type FormikValues } from 'formik'
import { Grid, Typography } from '@mui/material'
import { InputC } from '../../../components'

const AditionalForm = ({ formik }: FormikValues): JSX.Element => {
  return (
    <>
      <Typography variant="h5" component="h4" mb={5}>
        Contacto adicional
      </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Nombre"
              name="aditionalContact.name"
              type="text"
              value={formik.values.aditionalContact.name}
              onChange={formik.handleChange}
              placeholder='Ingresa el nombre'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Teléfono"
              name="aditionalContact.phone"
              type="text"
              value={formik.values.aditionalContact.phone}
              onChange={formik.handleChange}
              placeholder='Ingresa el teléfono'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Correo Electrónico"
              name="aditionalContact.email"
              type="text"
              value={formik.values.aditionalContact.email}
              onChange={formik.handleChange}
              placeholder='Ingresa el correo electrónico'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Dirección"
              name="aditionalContact.address"
              type="text"
              value={formik.values.aditionalContact.address}
              onChange={formik.handleChange}
              placeholder='Ingresa la dirección'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Parentesco"
              name="aditionalContact.relationship"
              type="text"
              value={formik.values.aditionalContact.relationship}
              onChange={formik.handleChange}
              placeholder='Ingresa el parentesco'
            />
          </Grid>
      </Grid>
    </>
  )
}

export default AditionalForm
