import { Box } from '@mui/joy'
import { StyledButton } from './style/styles'

const ButtonC = ({ children, type, startDecorator, variant, backgroundColor, color, width, onClick }: any): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <StyledButton color={color} backgroundColor={backgroundColor} fullWidth variant={variant} type={type} startIcon={startDecorator} width={width} onClick={onClick}>
            {children}
        </StyledButton>
    </Box>
  )
}

export default ButtonC
