import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const Private = ({ auth }: any): any => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate('/login')
    }
  }, [auth.isAuthenticated])

  return auth.isAuthenticated ? <Outlet /> : null
}

export default Private
