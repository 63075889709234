/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem, TextField, Typography, FormGroup, FormControlLabel, Box, CircularProgress } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import { CustomSwitch, HiddenInput, ContainedDeleteButton, ContainedImage, Item } from './styles/styled'
import { LinearProgress } from '../../../components'
import useHomeWorks from './hooks/useHomeworks'
import { DragAndDrop } from 'components'

enum Subject {
  math = 'MATH',
  spanish = 'SPANISH',
  science = 'SCIENCE',
  history = 'HISTORY',
  english = 'ENGLISH',
  art = 'ART',
  music = 'MUSIC',
  physical = 'PHYSICAL',
}

const subjects = [
  { value: Subject.math, label: 'Matemáticas' },
  { value: Subject.spanish, label: 'Español' },
  { value: Subject.science, label: 'Ciencias' },
  { value: Subject.history, label: 'Historia' },
  { value: Subject.english, label: 'Inglés' },
  { value: Subject.art, label: 'Arte' },
  { value: Subject.music, label: 'Música' },
  { value: Subject.physical, label: 'Educación Física' }

]

const AssignmentsSection = (): JSX.Element => {
  const {
    formik,
    file,
    errorFiles,
    handleFileChange,
    handleDrop,
    handleRemoveFile,
    uploadResume,
    status,
    handleSubmitFileFirebase,
    loading
  } = useHomeWorks()

  return (
    <Card>
      <CardHeader title="Crear Nueva Tarea o Actividad" />
      <CardContent>
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Título de la tarea" variant="outlined" name={'title'} value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              {formik.touched.title && formik.errors.title &&
                <Typography variant="body2" color="error">
                  {formik.errors.title}
                </Typography>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Seleccionar materia"
                variant="outlined"
                name='subject'
                value={formik.values.subject}
                onChange={formik.handleChange}
                onTouchEnd={formik.handleBlur}
              >
                {subjects.map((subject) => (
                  <MenuItem key={subject.value} value={subject.value}>
                    {subject.label}
                  </MenuItem>
                ))}
              </TextField>
              {formik.touched.subject && formik.errors.subject &&
                <Typography variant="body2" color="error">
                  {formik.errors.subject}
                </Typography>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Descripción de la tarea"
                variant="outlined"
                name={'description'}
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                rows={4}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description &&
                <Typography variant="body2" color="error">
                  {formik.errors.description}
                </Typography>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Fecha de entrega"
                variant="outlined"
                margin="normal"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="dueDate"
                onChange={formik.handleChange}
                value={formik.values.dueDate}
                onBlur={formik.handleBlur}
              />
              {formik.touched.dueDate && formik.errors.dueDate &&
                <Typography variant="body2" color="error">
                  {formik.errors.dueDate}
                </Typography>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <DragAndDrop handleDrop={handleDrop} onDragOver={(e) => { e.preventDefault() }} onClick={() => document.getElementById('fileInput')?.click()}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
                  <CloudUploadIcon />
                  <Typography variant="body2" color="textSecondary">
                    Arrastra y suelta el archivo aquí
                  </Typography>
                </Box>
                  <Typography variant="subtitle2" color="gray">
                    O haz clic para seleccionar un archivo
                  </Typography>
                <HiddenInput
                  id="fileInput"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  accept="image/*, application/pdf"
                />
              </DragAndDrop>
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{ cursor: file.length > 0 ? 'pointer' : 'not-allowed' }}
                onClick={() => { void handleSubmitFileFirebase() }}
                disabled={file.length === 0 || uploadResume.progress > 0}
              >
                Subir archivo
              </Button>
              {errorFiles && (
                <Typography variant="body2" color="error">
                  El archivo adjunto no puede ser mayor a 3mb
                </Typography>
              )}
              {formik.touched.file && formik.errors.file &&
                <Typography variant="body2" color="error">
                  {formik.errors.file}
                </Typography>
              }
              {status !== '' && <LinearProgress value={uploadResume.progress || 0} /> }
            </Grid>
          </Grid>
          <FormGroup>
            <FormControlLabel control={<CustomSwitch onChange={formik.handleChange} name="closedHomework" />} label="¿Cerrar tarea después de la fecha de entrega?" />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<CustomSwitch onChange={formik.handleChange} name="selectedParentDate" />} label="¿Permitir a los padres de familia seleccionar una fecha?" />
          </FormGroup>
          <CardActions>
            <Button variant="contained" color="primary" type="submit" disabled={formik.isSubmitting && !formik.isValid}>
              Crear Tarea
            </Button>
          </CardActions>
          {file.length > 0 && (
            <Typography variant="body2" color="error">
            <Typography fontSize="small" style={{ verticalAlign: 'middle' }}> El archivo adjunto estará disponible por 1 mes, después se borrará automáticamente del sistema.
            </Typography>
          </Typography>
          )}
        </form>
        { loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <CircularProgress />
          </Box>
        )}
        <Grid container spacing={2}>
          {file.length > 0 && file instanceof Array && file.map((f, index) => (
            f.type === 'application/pdf'
              ? (
                <Grid key={index} item xs={12} sm={3}>
                  <Item key={index}>
                    <Typography variant="body2" color="white">
                      {f.name}
                    </Typography>
                    <ContainedDeleteButton onClick={() => {
                      void handleRemoveFile(index, `homeworks/${formik.values.title}/${f.name}`)
                    }}>
                      <DeleteIcon />
                    </ContainedDeleteButton>
                  </Item>
                </Grid>
                )
              : f.type.includes('image')
                ? (
                  <Grid item xs={12} sm={3}>
                    <Item key={index}>
                      <ContainedDeleteButton onClick={() => {
                        void handleRemoveFile(index, `homeworks/${formik.values.title}/${f.name}`)
                      }}>
                        <DeleteIcon />
                      </ContainedDeleteButton>
                      <ContainedImage>
                        <img src={URL.createObjectURL(f)} alt={f.name} style={{ aspectRatio: '1/1', objectFit: 'cover', width: '100%', height: '100%' }} />
                      </ContainedImage>
                    </Item>
                  </Grid>
                  )
                : (
                  <Grid item xs={12} sm={6}>
                  </Grid>
                  )
          ))}
          {/* {file.length > 0 && (
            file instanceof Array
              ? file.map((f, index) => (
                <Typography key={index} variant="body2" color="white">
                  {f.name}
                </Typography>
              ))
              : (
                <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'grid', gap: 1, flexWrap: 'wrap', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center', margin: 1 }}>
                  {(file as File[]).map((f, index) => (
                    <Typography key={index} variant="body2">
                      {f.name}
                    </Typography>
                  ))}
                </Typography>
                )
          )} */}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AssignmentsSection
