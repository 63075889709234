
import { type Dispatch } from 'redux'
import { type AxiosResponse } from 'axios'
import { PAYMENT_PERCENTAGE, STUDENT_ALL } from '../../../redux/constants'
import { getStudents, getPercentagePayments } from '../../../api/services'

const getAllStudents = () => async (dispatch: any): Promise<void> => {
  dispatch({ type: STUDENT_ALL.STUDENT_ALL_LOADING })
  try {
    const res: AxiosResponse<any> = await getStudents()
    dispatch({ type: STUDENT_ALL.STUDENT_ALL_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: STUDENT_ALL.STUDENT_ALL_ERROR, payload: error })
  }
}

const getPercentagePayment = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: PAYMENT_PERCENTAGE.PAYMENT_PERCENTAGE_LOADING })
  try {
    const res: AxiosResponse<any> = await getPercentagePayments()
    dispatch({ type: PAYMENT_PERCENTAGE.PAYMENT_PERCENTAGE_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: PAYMENT_PERCENTAGE.PAYMENT_PERCENTAGE_ERROR, payload: error })
  }
}

export { getAllStudents, getPercentagePayment }
