import { type Dispatch } from 'redux'
import { type AxiosResponse } from 'axios'
import { GROUPS } from '../../constants'
import { getGroups as getGrps, createGroup as createGrp, getGroup, createStudentGrpService, getOnlyGroups } from '../../../api/services'
import { type StudentPayload } from '@/interface/StudentForm/studentForm.interface'
import { resetStdGroups } from '../../../redux/reducer/Groups/createStudentGroupsReducer'

export const getGroups = (limit: number, page: number) => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: GROUPS.GROUPS_LOADING })
  try {
    const res: AxiosResponse<any> = await getGrps(limit, page)
    dispatch({ type: GROUPS.GROUPS_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GROUPS.GROUPS_ERROR, payload: error })
    throw new Error('Error to get groups', error as any)
  }
}

export const createGroup = (payload: any, notifyPromise: any) => async (dispatch: any): Promise<any> => {
  dispatch({ type: GROUPS.GROUPS_CREATE_LOADING })
  try {
    const res: AxiosResponse<any> = await notifyPromise(createGrp(payload), { loading: 'Loading...', success: 'Se ha creado el grupo', error: 'No se ha podido crear el grupo' })
    dispatch({ type: GROUPS.GROUPS_CREATE_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GROUPS.GROUPS_CREATE_ERROR, payload: error })
    throw new Error('Error to create group', error as any)
  }
}

export const createStudentGroup = (payload: StudentPayload, token: string) => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: GROUPS.GROUPS_CREATE_STUDENT_LOADING })
  try {
    const res: AxiosResponse<any> = await createStudentGrpService(payload, token)
    dispatch({ type: GROUPS.GROUPS_CREATE_STUDENT_SUCCESS, payload: res.data })
  } catch (error: any) {
    dispatch({ type: GROUPS.GROUPS_CREATE_STUDENT_ERROR, payload: error?.response.data })
    throw new Error('Error to create student group', error)
  } finally {
    setTimeout(() => {
      dispatch(resetStdGroups())
    }, 3000)
  }
}

export const getGrpId = (id: string | undefined) => async (dispatch: any): Promise<any> => {
  dispatch({ type: GROUPS.GROUPS_ID_LOADING })
  try {
    const res: AxiosResponse<any> = await getGroup(id)
    dispatch({ type: GROUPS.GROUPS_ID_SUCCESS, payload: res.data.data })
  } catch (error) {
    dispatch({ type: GROUPS.GROUPS_ID_ERROR, payload: error })
    throw new Error('Error to get group', error as any)
  }
}

export const getOnlyGrp = () => async (dispatch: any): Promise<any> => {
  dispatch({ type: GROUPS.GET_ONLY_GROUP_LOADING })
  try {
    const res: AxiosResponse<any> = await getOnlyGroups()
    dispatch({ type: GROUPS.GET_ONLY_GROUP_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GROUPS.GET_ONLY_GROUP_ERROR, payload: error })
    throw new Error('Error to get group', error as any)
  }
}
