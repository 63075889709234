
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../redux/hook'
import { type DataUSR } from '../../interface'

interface Auth {
  status: boolean
  setStatus: any
  data: any
  dataUser: DataUSR
  loading: boolean
}

const useAuth = (): Auth => {
  const { data } = useAppSelector((state: { authReducer: any }) => state.authReducer)
  const [status, setStatus] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [dataUser, setDataUser] = useState<DataUSR>({
    access_token: '',
    user: {
      _id: '',
      email: '',
      name: '',
      role: '',
      isVerified: false
    }
  })

  useEffect(() => {
    const dataUsr = localStorage.getItem('dataUser')
    if (dataUsr !== null) {
      setStatus(true)
      setLoading(false)
      const data = JSON.parse(dataUsr)
      setDataUser(data)
    } else {
      setLoading(false)
      setStatus(false)
    }
  }, [data])

  return {
    status,
    setStatus,
    data,
    dataUser,
    loading
  }
}

export default useAuth
