import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { STUDENT_UPDATE } from '../../constants'
import { type StudentResult } from 'interface'

interface IState {
  loading: boolean
  error: null | string
  data: StudentResult | null
  success: boolean
}

const initialState: IState = {
  loading: false,
  error: null,
  data: null,
  success: false
}

const updateStudentReducer = createSlice({
  name: 'updateStudentReducer',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(STUDENT_UPDATE.STUDENT_LOADING_UPDATE, (state) => {
        state.loading = true
      })
      .addCase(STUDENT_UPDATE.STUDENT_SUCCESS_UPDATE, (state, action: PayloadAction<StudentResult>) => {
        state.loading = false
        state.success = true
        state.data = action.payload
      })
      .addCase(STUDENT_UPDATE.STUDENT_ERROR_UPDATE, (state, action: PayloadAction<string>) => {
        state.loading = false
        state.error = action.payload
      })
  },
  reducers: {
    resetStudent: () => initialState
  }
})

export const { resetStudent } = updateStudentReducer.actions

export default updateStudentReducer.reducer
