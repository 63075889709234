import * as React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

const a11yProps = (index: number): any => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

interface Props {
  labels: string[]
  children: React.ReactNode[]
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  value: number
  setValue: (value: number) => void
}

const CustomizedTabs = ({ labels, children, handleChange, value, setValue }: Props): JSX.Element => {
  const theme = useTheme()

  const handleChangeIndex = (index: number): void => {
    setValue(index)
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar position="static" style={{ boxShadow: 'none', borderRadius: '10px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {labels.map((label, index) => (
            <Tab label={label} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {children}
      </SwipeableViews>
    </Box>
  )
}

export default CustomizedTabs
