import { AUTH } from '../../constants/index'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface Auth {
  loading: boolean
  error: null | string
  data: null | any
  success: boolean
}

const initialState: Auth = {
  loading: false,
  error: null,
  data: null,
  success: false
}

interface AuthAction {
  type: string
  payload: any
}

const auth = createSlice({
  name: 'authReducer',
  initialState,
  extraReducers: builder => {
    builder.addCase(AUTH.AUTH_LOADING, (state, action) => {
      state.loading = true
    })
      .addCase(AUTH.AUTH_SUCCESS, (state, action: PayloadAction<AuthAction>) => {
        state.loading = false
        state.success = true
        state.data = action.payload
      })
      .addCase(AUTH.AUTH_ERROR, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      }
      )
  },
  reducers: {
    resetAuth: () => initialState
  }
})

export const { resetAuth } = auth.actions

export default auth.reducer
