import { veirfyAccount, sendVeirfyAccount } from '../../../api/services'
import { useState } from 'react'

const useVerifyAccount = (): { fetchVeirfyAccount: (tkn: string) => Promise<void>, isActivated: boolean | null, errorMessage: string, reSendEmailVeirfyAccount: (tkn: string) => Promise<void>, reSendEmail: string, loading: boolean } => {
  const [isActivated, setIsActivated] = useState<boolean | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [reSendEmail, setReSendEmail] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const fetchVeirfyAccount = async (tkn: string): Promise<void> => {
    setLoading(true)
    try {
      const res = await veirfyAccount(tkn)
      setIsActivated(res.data.message === 'Se activo la cuenta correctamente')
    } catch (error: any) {
      console.log(error.response.data.message)
      setErrorMessage(error.response.data.message === 'Token expired' ? 'expired_token' : error.response.data.message)
      setIsActivated(false)
    } finally {
      setLoading(false)
    }
  }

  const reSendEmailVeirfyAccount = async (tkn: string): Promise<void> => {
    setLoading(true)
    try {
      const res = await sendVeirfyAccount(tkn)
      setReSendEmail(res.data.message)
      return res
    } catch (error: any) {
      setReSendEmail('Error al enviar el correo')
      setIsActivated(false)
      return error
    } finally {
      setLoading(false)
    }
  }

  return { fetchVeirfyAccount, isActivated, errorMessage, reSendEmailVeirfyAccount, reSendEmail, loading }
}

export default useVerifyAccount
