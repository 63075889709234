import { useLocation, useParams } from 'react-router-dom'
import { Title, Skeleton } from 'components'
import { useStudent } from 'hooks'
import PaymentHistory from './PaymentHistory'
import { InfoValue } from '../Student/Student'
import { useMemo } from 'react'

const PaymentListHistory = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { data: user, loading, error } = useStudent(String(id))

  const useQuery = (): any => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const group = useQuery().get('group_id')

  if (!user && !loading && !error) return <Skeleton borderRadius />

  return (
    <>
    <Title title="Historial de pagos del alumno" />
      {user.group_id === null ? <InfoValue>{user.students.map((student: any) => student.group_id === group ? `${student.name} ${student.lastName}` : '').join('')}</InfoValue> : <InfoValue>{user.name} {user.lastName}</InfoValue>}
     <PaymentHistory historyPayments={user.historyPayments} />
    </>
  )
}

export default PaymentListHistory
