import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

export enum PaymentStatus {
  PENDING = 'PENDIENTE',
  PAID = 'PAGADO',
  CANCELED = 'CANCELADO',
  VALIDATED = 'VALIDANDO',
}

const Popover = styled(Box)<any>(({ theme, anchorEl }) => ({
  position: 'absolute',
  top: anchorEl ? `${anchorEl.getBoundingClientRect().bottom}px` : '40px', // Usar la posición dinámica del botón
  left: anchorEl ? `${anchorEl.getBoundingClientRect().left}px` : '30px', // Ajustar también horizontalmente
  backgroundColor: theme.palette.background.paper,
  border: '1px solid',
  borderColor: theme.palette.divider,
  boxShadow: theme.shadows[5],
  padding: '3px 20px',
  outline: 'none',
  borderRadius: '5px',
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'column',
  '& button': {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  [theme.breakpoints.down('md')]: {
    top: '20%',
    left: '5%', // Ajustar para pantallas pequeñas
    right: '5%' // Centramos el popover en pantallas pequeñas
  }
}))

const PaymentStatusTypography = styled(Typography)<{ status: PaymentStatus }>(({ theme, status }) => ({
  color: status === PaymentStatus.PAID ? 'green' : status === PaymentStatus.CANCELED ? 'red' : status === PaymentStatus.VALIDATED ? 'blue' : 'orange'
}))

export { Popover, PaymentStatusTypography }
