import { type UserStudent } from 'interface'
import { api, apiAuth } from '../../config'
import { type UserRole } from '../../../redux/actions/users/users.action'

const getAllUsers = async (role?: UserRole): Promise<any> => await api.get(`/users?role=${role}`)
const getStudentId = async (token: string, id: string): Promise<any> => await api.get(`/users/student/${id}`, {
  headers:
    {
      Authorization: `Bearer ${token}`
    }
})

const createStudentForm = async (token: string, payload: any): Promise<any> => await api.post('/users/student', payload, {
  headers:
    {
      Authorization: `Bearer ${token}`
    }
})

const veirfyAccount = async (token: string): Promise<any> => await apiAuth.put(`/users/verify_account/${token}`, {}, {
  headers:
    {
      Authorization: `Bearer ${token}`
    }
})

const sendVeirfyAccount = async (token: string): Promise<any> => await apiAuth.post(`/users/resend-verify-email/${token}`, {}, {
  headers:
    {
      Authorization: `Bearer ${token}`
    }
})

const getStudents = async (): Promise<any> => await api.get('users/students')

const updateStudentId = async (id: string | undefined, payload: UserStudent): Promise<any> => await api.put(`/users/student/${id}`, payload)

const updateMultiStudentId = async (id: string | undefined, mainId: string | undefined, payload: UserStudent): Promise<any> => await api.put(`/users/student/payment/${id}/${mainId}`, payload)

const getPercentagePayments = async (): Promise<any> => await api.get('users/students/percentage')

export { getAllUsers, getStudentId, createStudentForm, veirfyAccount, updateStudentId, getStudents, getPercentagePayments, sendVeirfyAccount, updateMultiStudentId }
