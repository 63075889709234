
import { useEffect } from 'react'
import { useAppSelector } from '../../redux/hook'
import { type User } from '../../interface'
import { type UserRole, getUsers } from '../../redux/actions'
import { useAppDispatch } from '../../redux/tpye_hooks'
import { resetUsers } from '../../redux/reducer/Users/usersReducer'

const useGroups = (role: UserRole): any => {
  const { data, success } = useAppSelector((state: { usersReducer: any }) => state.usersReducer)
  const dispatch = useAppDispatch()

  const dataUsers = async (): Promise<User[]> => {
    const users = await dispatch(getUsers(role))
    return users
  }

  useEffect(() => {
    void dataUsers().then((res) => {})

    return () => {
      resetUsers()
    }
  }, [role])

  return {
    status,
    data,
    success
  }
}

export default useGroups
