import { type AxiosResponse } from 'axios'
import { TEACHER } from '../../../redux/constants'
import { createTeacherService, assignedTeacherGroup } from '../../../api/services/Teacher/teacher.service'
import { resetBoolean } from '../../reducer/Users/teacherReducer'

const createTeacher = (payload: any) => async (dispatch: any): Promise<any> => {
  dispatch({ type: TEACHER.TEACHER_LOADING })
  try {
    const res: AxiosResponse<any> = await createTeacherService(payload)
    if (res.data._id) {
      dispatch(asignedTeacherGroup(payload.assignedGroupId, res.data._id))
    }
    dispatch({ type: TEACHER.TEACHER_SUCCESS, payload: res.data.data })
  } catch (error: any) {
    dispatch({ type: TEACHER.TEACHER_ERROR, payload: error.response.data.message })
  } finally {
    setTimeout(() => {
      dispatch(resetBoolean())
    }, 30000)
  }
}

const asignedTeacherGroup = (id: string, mainTeacherId: string) => async (dispatch: any): Promise<any> => {
  dispatch({ type: TEACHER.TEACHER_LOADING_A })
  try {
    const res: AxiosResponse<any> = await assignedTeacherGroup(id, mainTeacherId)
    dispatch({ type: TEACHER.TEACHER_SUCCESS_A, payload: res.data.data })
  } catch (error) {
    dispatch({ type: TEACHER.TEACHER_ERROR_A, payload: error })
  }
}

export { createTeacher, asignedTeacherGroup }
