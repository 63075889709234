import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useSteps from './hooks/useSteps'
import { CustomStepper } from './style/tyles'

interface PropsSteps {
  steps: string[]
  children: React.ReactNode
  activeStep: number
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  formik?: any
  success?: boolean
}

const HorizontalLinearStepper = ({
  steps,
  children,
  activeStep,
  setActiveStep,
  formik,
  success
}: PropsSteps): JSX.Element => {
  const { handleReset, errorReducer: error, isStepOptional, isStepSkipped, handleBack, handleNext, hasErrorsInCurrentStep } = useSteps(activeStep, setActiveStep, formik)

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        handleReset()
      }, 2000)
    }
  }, [success])

  return (
    <Box sx={{ width: '100%', mt: 2, p: 2 }}>
      {success && (
        <Alert severity="success">
          <AlertTitle>¡Éxito!</AlertTitle>
          El alumno ha sido registrado correctamente
        </Alert>
      )}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      )}

      <CustomStepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}

          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }

          return (
            <Step key={`${label}${index}`} {...stepProps}>
              <StepLabel {...labelProps}>
                {index === activeStep ? label : ''}
              </StepLabel>
            </Step>
          )
        })}
      </CustomStepper>
      {activeStep === steps.length
        ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              Todos los pasos completados - ¡Has terminado!
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reiniciar</Button>
            </Box>
          </>
          )
        : (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
              {children}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '40px' }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Volver
                </Button>
                {activeStep === steps.length - 1 && (
                  <></>
                )}
                {activeStep !== steps.length - 1 && (
                  <Button
                    onClick={handleNext}
                    disabled={hasErrorsInCurrentStep()}
                    variant="contained"
                    color="primary"
                    style={{
                      opacity: hasErrorsInCurrentStep() ? 0.5 : 1,
                      pointerEvents: hasErrorsInCurrentStep() ? 'none' : 'auto'
                    }}
                  >
                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                  </Button>
                )}
              </Box>
            </Box>
          </>
          )}
      <CustomStepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}

          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }

          return (
            <Step key={`${label}${index}`} {...stepProps}>
              <StepLabel {...labelProps}>
                {index === activeStep ? label : ''}
              </StepLabel>
            </Step>
          )
        })}
      </CustomStepper>
    </Box>
  )
}

export default HorizontalLinearStepper
