import { GROUPS } from '../../constants'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// interface IState {
//   loading: boolean
//   error: null | string
//   data: any[]
//   success: boolean
// }

const initialState: any = {
  loading: false,
  error: null,
  data: [],
  success: false
}

// interface GroupsAction {
//   type: string
//   payload: any[]
// }

const getGrpsOnly = createSlice({
  name: 'getGrpsOnly',
  initialState,
  extraReducers: builder => {
    builder.addCase(GROUPS.GET_ONLY_GROUP_LOADING, (state) => {
      state.loading = true
    })
      .addCase(GROUPS.GET_ONLY_GROUP_SUCCESS, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.success = true
        state.data = action.payload as unknown as any[]
      })
      .addCase(GROUPS.GET_ONLY_GROUP_ERROR, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      }
      )
  },
  reducers: {
    resetGroups: () => initialState
  }
})

export const { resetGroups } = getGrpsOnly.actions

export default getGrpsOnly.reducer
