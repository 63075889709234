import toast from 'react-hot-toast'

interface PayloadNotify {
  loading: string
  success: string
  error: string
}
type NotifyPromise = (promise: any, payload: PayloadNotify) => Promise<void>

export interface UseUtils {
  notifyPromise: NotifyPromise
}

const useUtils = (): UseUtils => {
  const notifyPromise: NotifyPromise = async (promise, payload): Promise<void> => {
    void toast.promise(promise, { ...payload })
  }
  return { notifyPromise }
}

export default useUtils
