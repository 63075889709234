import * as React from 'react'
import LinearProgress, { type LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const LinearWithValueLabel = ({ value }: { value: number }): JSX.Element => {
  const [progress, setProgress] = React.useState(value)

  React.useEffect(() => {
    console.log('value', value)
    setProgress(value)
    return () => {
      setProgress(0)
    }
  }, [value])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  )
}

export default LinearWithValueLabel
