import { DragAndDrop } from './style/styles'

interface DropProps {
  children: React.ReactNode
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const DragDrop = ({ children, handleDrop, onDragOver, onClick }: DropProps): JSX.Element => {
  return (
    <DragAndDrop onDrop={handleDrop} onDragOver={onDragOver} onClick={onClick}>
      {children}
    </DragAndDrop>
  )
}

export default DragDrop
