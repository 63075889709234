import { type AxiosResponse } from 'axios'
import { type LoginInterfrace } from 'interface'
import { AUTH, GENERATE_LINK } from '../../../redux/constants'
import { getLink, login, redirectLink } from '../../../api/services/Auth/auth.service'
import { resetState } from '../../../redux/reducer/Auth/generateLink.reducer'
import { resetAuth } from '../../../redux/reducer/Auth/auth.reducer'

const signIn = (values: LoginInterfrace) => async (dispatch: any): Promise<any> => {
  dispatch({ type: AUTH.AUTH_LOADING })
  try {
    const res: AxiosResponse<any, any> = await login(values)
    dispatch({ type: AUTH.AUTH_SUCCESS, payload: res.data })
    localStorage.setItem('dataUser', JSON.stringify(res.data))
    return res.data
  } catch (error: any) {
    dispatch({ type: AUTH.AUTH_ERROR, payload: error.response.data.message })
    return error
  } finally {
    setTimeout(() => {
      dispatch(resetAuth())
    }, 3000)
  }
}

const generateShortLink = () => async (dispatch: any): Promise<any> => {
  dispatch({ type: GENERATE_LINK.GENERATE_LINK_LOADING })
  try {
    const res: AxiosResponse<any, any> = await getLink()
    dispatch({ type: GENERATE_LINK.GENERATE_LINK_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: GENERATE_LINK.GENERATE_LINK_ERROR, payload: error })
    return error
  } finally {
    setTimeout(() => {
      dispatch(resetState())
    }, 3000)
  }
}

const redirect = (url: string) => async (dispatch: any): Promise<any> => {
  try {
    dispatch({ type: 'REDIRECT' })
    return await redirectLink(url)
  } catch (error) {
    console.log(error)
  }
}

export { signIn, generateShortLink, redirect }
