import styled from '@emotion/styled'
import Box from '@mui/material/Box'

export interface ViewStackProps {
  /**
     * The number of views in the stack.
     * This is used to calculate the height of the stack.
     * @default 0
     * */
  width?: number
  margin?: string | number
  alignItems?: string | undefined
  viewHeight?: number | null
  important?: boolean
  backgroundColor?: string | undefined
  color?: string | undefined
  backgroundImage?: string | undefined
  gradientColor?: string | undefined
}

export const ViewStack = styled(Box)<ViewStackProps>`
    display: flex;
    justify-content: center;
    width: ${({ width }) => (width != null) ? `${width * 100}%` : '100%'};
    height: ${({ viewHeight }) => (viewHeight != null) ? `${viewHeight * 100}vh` : '100%'};
    align-items: ${({ alignItems }) => (alignItems != null) ? alignItems : 'center'};
    transition: transform 0.3s ease-in-out;
    background-image: ${({ backgroundImage }) => (backgroundImage != null) ? backgroundImage : 'none'};
    color: ${({ color }) => (color != null) ? color : 'black'};
    margin: ${({ margin }) => (margin != null) ? margin : '0'};
    padding: ${({ paddingX, paddingY }) => `${paddingY}px ${paddingX}px`};
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    /* gradient color */
    background: ${({ gradientColor }) => (gradientColor != null) ? gradientColor : ''};
    @media (max-width: 600px) {
        width: '100%';
        padding: 10px;
    }

`

//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-image: url(${background});
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
//   padding: 20px;
