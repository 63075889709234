export const GROUPS = {
  GROUPS_LOADING: 'GROUPS_LOADING',
  GROUPS_SUCCESS: 'GROUPS_SUCCESS',
  GROUPS_ERROR: 'GROUPS_ERROR',
  GROUPS_RESET: 'GROUPS_RESET',

  GROUPS_CREATE_LOADING: 'GROUPS_CREATE_LOADING',
  GROUPS_CREATE_SUCCESS: 'GROUPS_CREATE_SUCCESS',
  GROUPS_CREATE_ERROR: 'GROUPS_CREATE_ERROR',
  GROUPS_CREATE_RESET: 'GROUPS_CREATE_RESET',

  GROUPS_ID_LOADING: 'GROUPS_CREATE_LOADING',
  GROUPS_ID_SUCCESS: 'GROUPS_CREATE_SUCCESS',
  GROUPS_ID_ERROR: 'GROUPS_CREATE_ERROR',
  GROUPS_ID_RESET: 'GROUPS_CREATE_RESET',

  GROUPS_CREATE_STUDENT_LOADING: 'GROUPS_CREATE_STUDENT_LOADING',
  GROUPS_CREATE_STUDENT_SUCCESS: 'GROUPS_CREATE_STUDENT_SUCCESS',
  GROUPS_CREATE_STUDENT_ERROR: 'GROUPS_CREATE_STUDENT_ERROR',
  GROUPS_CREATE_STUDENT_RESET: 'GROUPS_CREATE_STUDENT_RESET',

  GET_ONLY_GROUP_LOADING: 'GET_ONLY_GROUP_LOADING',
  GET_ONLY_GROUP_SUCCESS: 'GET_ONLY_GROUP_SUCCESS',
  GET_ONLY_GROUP_ERROR: 'GET_ONLY_GROUP_ERROR',
  GET_ONLY_GROUP_RESET: 'GET_ONLY_GROUP_RESET'
}
