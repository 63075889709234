import React, { useEffect, useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Grid, Paper, Typography, Button, TextField, MenuItem, InputLabel, Select, FormHelperText } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { getOnlyGrp } from '../../redux/actions'
import { getPercentagePayment } from '../../redux/actions/students'
import useHome from './hooks/useHome'
// import { type PaymentPercentageState } from '../../redux/reducer/PaymentPercentage/paymentPercentage'
import { Spinner } from '../StudentForm/styles/styles'
import { DragAndDrop, LinearProgress, Title } from 'components'
import { ContainedDeleteButton, ContainedImage, HiddenInput, Item } from '../SectionTeachersView/HomeWorks/styles/styled'

const Home: React.FC = () => {
  const { events, loadingEvent, handleDrop, uploadResume, handleFileChange, handleSubmitFileFirebase, file, formik, handleRemoveFile, errorFiles, loading: loadingFile } = useHome()
  const dispatch = useAppDispatch()
  const [maxCharacters, setMaxCharacters] = useState(150)
  const { loading: loadingGrps, success: successGrps, data } = useAppSelector((state) => state.grpsOnlyReducer)
  const { data: students, loading } = useAppSelector((state: any) => state.paymentPercentageReducer)

  useEffect(() => {
    void dispatch(getOnlyGrp())
    void dispatch(getPercentagePayment())
  }, [])
  useEffect(() => {
    console.log('maxCharacters', maxCharacters)
    console.log('maxCharacters', typeof maxCharacters)
  }, [maxCharacters])

  useEffect(() => {
    console.log('formik.errors', formik.errors)
  }, [formik.errors])

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2 }}>
            {loading
              ? (
                <Spinner />
                )
              : (
              <>
                <Typography variant="h6">Pagos de Mensualidad</Typography>
                <Typography variant="h3" color="primary">{students?.totalStudentsWithPayment ?? 0}%</Typography>
                <Typography variant="body2" color="error">{students?.totalStudentsWithoutPayment ?? 0}% Pendientes</Typography>
              </>
                )}
            </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2 }}>
            {loading
              ? (
                <Spinner />
                )
              : (
              <>
                <Typography variant="h6">Total de Alumnos</Typography>
                <Typography variant="h3">{students?.totalStudents}</Typography>
                <Typography variant="body2">Alumnos registrados</Typography>
              </>
                )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2 }}>
            {loading
              ? (
                <Spinner />
                )
              : (
              <>
                <Typography variant="h6">Maestros Activos</Typography>
            <Typography variant="h3">{students?.totalTeachers}</Typography>
            <Typography variant="body2">Maestros en plantilla</Typography>
              </>
                )}

          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Crear Evento</Typography>
            <TextField
              fullWidth
              label="Nombre del Evento"
              variant="outlined"
              margin="normal"
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              onBlur={formik.handleBlur}
              onFocus={formik.handleFocus}
              onKeyUp={formik.handleKeyUp}
              onTouchStart={formik.handleTouchStart}

            />
            {formik.errors.title && formik.touched.title &&
             <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{
              formik.errors.title
             }</FormHelperText>}
            <TextField
              fullWidth
              label="Lugar del Evento"
              variant="outlined"
              margin="normal"
              name="place"
              onChange={formik.handleChange}
              value={formik.values.place}
              onBlur={formik.handleBlur}
              onFocus={formik.handleFocus}
              onKeyUp={formik.handleKeyUp}
              onTouchStart={formik.handleTouchStart}
            />
            {formik.errors.palce && formik.touched.palce &&
             <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{
              formik.errors.place
             }</FormHelperText>}
            <TextField
              fullWidth
              label="Descripción del Evento"
              variant="outlined"
              margin="normal"
              name="description"
              onChange={(e) => {
                if (e.target.value.length > 150) return
                formik.handleChange(e)
                setMaxCharacters(150 - e.target.value.length)
              }}
              value={formik.values.description}
              onBlur={formik.handleBlur}
              onFocus={formik.handleFocus}
              onKeyUp={formik.handleKeyUp}
              onTouchStart={formik.handleTouchStart}
              maxRows={4}
              multiline
            />
            <Typography variant="body2" fontStyle={{ fontStyle: 'italic' }} color="textSecondary">{`Máximo ${maxCharacters} caracteres`}</Typography>
            {formik.touched.description && formik.errors.description &&
             <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{
              formik.errors.description
             }</FormHelperText>}
            <TextField
              fullWidth
              label="Fecha del Evento"
              variant="outlined"
              margin="normal"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="date"
              onChange={formik.handleChange}
              value={formik.values.date}
              onBlur={formik.handleBlur}
              onFocus={formik.handleFocus}
              onKeyUp={formik.handleKeyUp}
              onTouchStart={formik.handleTouchStart}
            />
            {formik.touched.date && formik.errors.date &&
             <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{
              formik.errors.date
             }</FormHelperText>}
            <TextField
              fullWidth
              label="Hora del Evento"
              variant="outlined"
              margin="normal"
              type="time"
              InputLabelProps={{ shrink: true }}
              name="time"
              onChange={formik.handleChange}
              value={formik.values.time}
              onBlur={formik.handleBlur}
              onFocus={formik.handleFocus}
              onKeyUp={formik.handleKeyUp}
              onTouchStart={formik.handleTouchStart}
            />
            {formik.touched.time && formik.errors.time &&
             <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{
              formik.errors.time
             }</FormHelperText>}
            <Grid item xs={12} sm={6}>
              <DragAndDrop handleDrop={handleDrop} onDragOver={(e) => { e.preventDefault() }} onClick={() => document.getElementById('fileInput')?.click()}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
                  <CloudUploadIcon />
                  <Typography variant="body2" color="textSecondary">
                    Arrastra y suelta el archivo aquí
                  </Typography>
                </Box>
                  <Typography variant="subtitle2" color="gray">
                    O haz clic para seleccionar un archivo
                  </Typography>
                <HiddenInput
                  id="fileInput"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  accept="image/*"
                  onBlur={formik.handleBlur}
                  onFocus={formik.handleFocus}
                  onKeyUp={formik.handleKeyUp}
                  onTouchStart={formik.handleTouchStart}
                />
              </DragAndDrop>
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                fullWidth
                startIcon={<CloudUploadIcon />}
                sx={{ cursor: file.length > 0 ? 'pointer' : 'not-allowed' }}
                onClick={() => { void handleSubmitFileFirebase() }}
                disabled={file.length === 0 || uploadResume.progress > 0}
              >
                {loadingFile ? <Spinner width='25px' height='25px' /> : 'Subir Archivo'}
              </Button>
              {errorFiles && (
                <Typography variant="body2" color="error">
                  El archivo adjunto no puede ser mayor a 3mb
                </Typography>
              )}
              { formik.errors.file && <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{formik.errors.file}</FormHelperText> }

              {status !== '' && <LinearProgress value={uploadResume.progress || 0} /> }
            </Grid>
            <Grid container spacing={2}>
          {file.length > 0 && file instanceof Array && file.map((f, index) => (
            f.type === 'application/pdf'
              ? (
                <Grid key={index} item xs={12} sm={3}>
                  <Item>
                    <Typography variant="body2" color="white">
                      {f.name}
                    </Typography>
                    <ContainedDeleteButton onClick={() => {
                      void handleRemoveFile(index, `events_shool/${formik.values.title.trim().toLowerCase().replace(/\s/g, '') && 'events_shool'}/${f.name.trim().toLowerCase().replace(/\s/g, '')}`)
                    }}>
                      <DeleteIcon />
                    </ContainedDeleteButton>
                  </Item>
                </Grid>
                )
              : f.type.includes('image')
                ? (
                  <Grid key={index} item xs={12} sm={3}>
                    <Item>
                      <ContainedDeleteButton onClick={() => {
                        void handleRemoveFile(index, `events_shool/${formik.values.title.trim().toLowerCase().replace(/\s/g, '') && 'events_shool'}/${f.name.trim().toLowerCase().replace(/\s/g, '')}`)
                      }}>
                        <DeleteIcon />
                      </ContainedDeleteButton>
                      <ContainedImage>
                        <img src={URL.createObjectURL(f)} alt={f.name} style={{ aspectRatio: '1/1', objectFit: 'cover', width: '100%', height: '100%' }} />
                      </ContainedImage>
                    </Item>
                  </Grid>
                  )
                : (
                  <Grid item xs={12} sm={6}>
                  </Grid>
                  )
          ))}
        </Grid>
            <div style={{ marginTop: '20px', width: '100%' }}>
              {loadingGrps && <Typography variant="body2">Cargando grupos...</Typography>}
              {successGrps &&
                <><InputLabel id="group_id">Selecciona una opción</InputLabel><Select id="group_id" fullWidth variant="outlined" onChange={formik.handleChange} name='group_id' value={formik.values.group_id}>
                <MenuItem selected value="">Selecciona una opción</MenuItem>
                <MenuItem value="all">Toda la escuela</MenuItem>
                  {[...data.data]
                    .sort((a: any, b: any) => a.name - b.name)
                    .map((text: any) => (
                      <MenuItem value={text.id} key={text.id}>
                        {text.grade} {text.group} - {text.name}
                      </MenuItem>
                    ))
                  }
                </Select>
                </>
              }
            </div>
            {/* <TextField
              fullWidth
              select
              label="Tipo de Evento"
              variant="outlined"
              margin="normal"
              name="eventType"
              onChange={handleChange}
              value={newEvent.eventType}
            >
              <MenuItem value="Toda la escuela">Toda la escuela</MenuItem>
              <div style={{ marginTop: '20px', width: '100%' }}>
                {loadingGrps && <Typography variant="body2">Cargando grupos...</Typography>}
                <div style={{ marginTop: '20px', width: '100%' }}>
                  {successGrps &&
                    [...data.data]
                      .sort((a: any, b: any) => a.name - b.name)
                      .map((text: any) => (
                        <MenuItem value={text.id} key={text.id}>
                          {text.grade} {text.group} - {text.name}
                        </MenuItem>
                      ))
                  }
                </div>
              </div>
            </TextField> */}
            <Button sx={{ mt: 5, opacity: loadingEvent || !formik.isValid ? 0.5 : 1 }} variant="contained" color="primary" fullWidth onClick={formik.handleSubmit} disabled={loadingEvent || !formik.isValid || formik.isSubmitting || uploadResume.progress === 0 }>
              {loadingEvent ? <Spinner width='25px' height='25px' /> : 'Crear Evento'}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Actividades de Maestros</Typography>
            {/* <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body1">Juan Pérez - 1º A</Typography>
              <CheckCircleIcon color="success" sx={{ ml: 2 }} />
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body1">María González - 2º B</Typography>
              <CheckCircleIcon color="success" sx={{ ml: 2 }} />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">Roberto López - 3º A</Typography>
              <CancelIcon color="error" sx={{ ml: 2 }} />
            </Box> */}
            <Typography variant="body2">No hay actividades de maestros</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Title title="Próximos Eventos" variant="h4" />

      <Paper sx={{ padding: 2, marginBottom: 2 }}>
        {events.data.length === 0 && (
          <Title title="No hay eventos próximos" variant="h6" />
        )}
        {events.data.length > 0 && (
          events.data.map((event: any, index: number) => (
            <Box display="flex" alignItems="center" mt={2} key={index}>
              <EventIcon color="primary" />
              <Box ml={2}>
                <Typography variant="h6">{event.title}</Typography>
                <Typography variant="body2">{new Date(event.date).toLocaleDateString()}</Typography>
              </Box>
            </Box>
          ))
        )}
      </Paper>
    </Box>
  )
}

export default Home
