
import { useEffect } from 'react'
import { useAppSelector } from '../../redux/hook'
import { type StudentResult } from '../../interface'
import { getStudent } from '../../redux/actions'
import { useAppDispatch } from '../../redux/tpye_hooks'
import { resetStudent } from '../../redux/reducer/Users/studentReducer'

const useStudent = (id: string): any => {
  const { data, success } = useAppSelector((state: { studentReducer: any }) => state.studentReducer)
  const dispatch = useAppDispatch()

  const dataUsers = async (): Promise<StudentResult> => {
    const users = await dispatch(getStudent(id))
    return users
  }

  useEffect(() => {
    void dataUsers().then((res) => { })
    return () => {
      dispatch(resetStudent())
    }
  }, [])

  return {
    status,
    data,
    success
  }
}

export default useStudent
