import { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import ReceiptIcon from '@mui/icons-material/Receipt'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import PaidIcon from '@mui/icons-material/Paid'
import SyncIcon from '@mui/icons-material/Sync'
import CancelIcon from '@mui/icons-material/Cancel'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import SaveIcon from '@mui/icons-material/Save'
import { type GridColDef } from '@mui/x-data-grid'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import { useSeparateStudentsByGroup } from './hooks/useSeparateStudentsByGroup'
import { Box, Button, FormGroup, FormControlLabel, Radio, RadioGroup, TextField, Tooltip, Select, MenuItem } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { DataGrid, ViewStack, BasicModal, Popover, Title } from '../../components'
import { useGroup } from '../../hooks'
import { type UserStudent } from 'interface'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { updateStudent, updateMultiStudent } from '../../redux/actions'
import { PaymentStatus, PaymentStatusTypography } from './styled/styles'

const Students = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [studentsDataGrid, setStudentsDataGrid] = useState<any>([])

  const {
    users,
    loading,
    openModal,
    handleCloseModal,
    student,
    editStudent,
    updatePaymentStatus
  } = useGroup(id)

  const formattedStudents = useSeparateStudentsByGroup(users?.students_id || null)
  const columns: GridColDef[] = [
    {
      field: 'lastName',
      headerName: 'Apellido',
      width: 110,
      renderCell: (params) => (
        <Typography>{params.row.lastName}</Typography>
      )
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 110,
      renderCell: (params) => (
        <Typography>{params.row.name}</Typography>
      )
    },
    {
      field: 'curp',
      headerName: 'Curp',
      width: 200,
      renderCell: (params) => (
        <Typography sx={{ textTransform: 'uppercase' }}>{params.row.curp || 'No disponible'}</Typography>
      )
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      width: 130,
      renderCell: (params) => (
        <Typography>{params.row.phone || 'No disponible'}</Typography>
      )
    },
    {
      field: 'paymentAmount',
      headerName: 'Mensualidad',
      width: 130,
      renderCell: (params) => (
        <Typography>{params.row.paymentAmount === 0 ? 3200 : params.row.paymentAmount || 'No disponible'}</Typography>
      )
    },
    {
      field: 'totalAmount',
      headerName: 'Mensualidad c/d',
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row.totalAmount === 0 ? 3200 : params.row.totalAmount || 'No disponible'}</Typography>
      )
    },
    {
      field: 'paymentStatus',
      headerName: 'Estatus de pago',
      width: 150,
      renderCell: (params) => (
        <Box >
          <PaymentStatusTypography status={params.row.paymentStatus} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
            {params.row.paymentStatus === PaymentStatus.VALIDATED ? <SyncIcon /> : params.row.paymentStatus === PaymentStatus.PENDING ? <AccessTimeIcon /> : params.row.paymentStatus === PaymentStatus.PAID ? <TaskAltIcon /> : <CancelIcon />}
            {params.row.paymentStatus}
          </PaymentStatusTypography>
        </Box>
      )
    },
    {
      field: 'paymentEdit',
      headerName: 'Confirmar Pago',
      width: 110,
      renderCell: (params) => (
        <ViewStack sx={{ top: '0', height: '100%!important' }}>
          <Popover icon={<PaidIcon />}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Select
                value={params.row.paymentStatus}
                onChange={(e) => { updatePaymentStatus(e.target.value as PaymentStatus, params.row._id, params.row.mainId) }}
              >
                <MenuItem value={PaymentStatus.VALIDATED}>Por validar</MenuItem>
                <MenuItem value={PaymentStatus.PAID}>Pagado</MenuItem>
                <MenuItem value={PaymentStatus.PENDING}>Pendiente</MenuItem>
                <MenuItem value={PaymentStatus.CANCELED}>Cancelado</MenuItem>
              </Select>
            </Box>
          </Popover>
        </ViewStack>
      )
    },
    {
      field: 'show',
      headerName: 'Ver',
      width: 50,
      renderCell: (params) => (
        <ViewStack sx={{ top: '0', height: '100%!important' }}>
          <Tooltip title="Ver Alumno" placement="top">
            <Link style={{ textDecoration: 'none', color: 'inherit' }}
            to={`/student/${params.row._id}?group_id=${params.row.group_id}&mainId=${params.row.curp}`}>
              <RemoveRedEyeRoundedIcon />
            </Link>
          </Tooltip>
        </ViewStack>
      )
    },
    {
      field: 'mas',
      headerName: 'Más',
      width: 100,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Popover>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button sx={{ justifyContent: 'flex-start' }} onClick={() => { editStudent(params.row) }} startIcon={<EditRoundedIcon />}>
                Ajustar mensualidad
              </Button>
              <Button sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate(`/payments_list/${params.row._id}?group_id=${params.row.group_id}`) }} startIcon={<ReceiptIcon />}>
                Historial de pagos
              </Button>
              <Button sx={{ justifyContent: 'flex-start' }} onClick={() => { editStudent(params.row) }} startIcon={<PersonRemoveRoundedIcon />} color="error">
                Dar de baja
              </Button>
            </Box>
          </Popover>
        </div>
      )
    }
  ]

  useEffect(() => {
    const students = formattedStudents.filter(
      (student, index, self) =>
        self.findIndex(s => s.curp === student.curp && s.mainId === student.mainId) === index
    )
    setStudentsDataGrid(students)
  }, [formattedStudents])

  return (
    <ViewStack sx={{ padding: '0 12px' }} alignItems='flex-start'>
      <BasicModal open={openModal} handleClose={handleCloseModal}>
        <ComponentToEdit {...student} />
      </BasicModal>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <Title title="Alumnos" variant="h4" />
        <DataGrid
          rows={studentsDataGrid}
          columns={columns}
          getRowId={(row: any) => `${row.curp}`}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 11
              }
            },
            filter: {
              items: []
            }
          }}
          pageSizeOptions={[11]}
          loading={loading}
        />
        {/* {!loading && formattedStudents.length > 0 && (
        )} */}
      </div>
    </ViewStack>
  )
}

export default Students

const ComponentToEdit = ({ _id, mainId, paymentAmount, totalAmount }: UserStudent): JSX.Element => {
  const [type, setType] = useState<string>('percentage')
  const [valueAdjust, setValueAdjust] = useState<number>()
  const { loading } = useAppSelector((state: { updateStudentReducer: any }) => state.updateStudentReducer)

  const dispatch = useAppDispatch()

  const adjustPayment = (x: number): number => {
    const adjustValue = valueAdjust ?? 0
    if (type === 'percentage') {
      if (x === 0) {
        return 3200 - (3200 * (adjustValue / 100))
      } else {
        return x - (x * (adjustValue / 100))
      }
    } else {
      if (x === 0) {
        return 3200 - adjustValue
      } else {
        return x - adjustValue
      }
    }
  }

  const handleSave = (): void => {
    const payload: UserStudent = {
      discountAmount: valueAdjust,
      totalAmount: adjustPayment(Number(paymentAmount)),
      typeDiscount: type,
      paymentAmount: paymentAmount === 0 ? 3200 : paymentAmount
    }

    const payloadActions = {
      errorMessage: 'Error al ajustar la mensualidad',
      message: 'Mensualidad ajustada correctamente'
    }

    if (mainId) {
      void dispatch(updateMultiStudent(_id, mainId, payload, payloadActions.errorMessage, payloadActions.message))
      return
    }

    void dispatch(updateStudent(_id, payload, payloadActions.errorMessage, payloadActions.message))
  }

  return (
    <Box>
      <Typography textAlign={'center'} variant='h5'>Ajuste de Mensualidad Escolar</Typography>
      <Box mt={5} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box gap={2} mt={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography textAlign={'center'} variant='h6'>Mensualidad:</Typography>
          <Typography textAlign={'center'}>{`$ ${paymentAmount === 0 ? 3200 : paymentAmount} mx`}</Typography>
        </Box>
        <Box gap={2} mt={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography textAlign={'center'} sx={{ fontWeight: 'bold', fontSize: '14px' }}>Descuento Actual:</Typography>
          <Typography textAlign={'center'}>$ {totalAmount} mx</Typography>
        </Box>
        <Box gap={2} mt={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography textAlign={'center'} sx={{ fontWeight: 'bold', fontSize: '14px' }}>
            {adjustPayment(Number(paymentAmount)) === 3200 ? 'Antes' : 'Después'} del descuento:
          </Typography>
          <Typography textAlign={'center'}>$ {adjustPayment(Number(paymentAmount))} mx</Typography>
        </Box>
        <Box mt={5} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography textAlign={'center'} variant='h6'>Ajustar Mensualidad</Typography>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel value="percentage" control={<Radio checked={type === 'percentage'} onChange={() => { setType('percentage') }} />} label="Porcentaje" />
              <FormControlLabel value="fixedAmount" control={<Radio checked={type === 'fixedAmount'} onChange={() => { setType('fixedAmount') }} />} label="Monto fijo" />
            </RadioGroup>
          </FormGroup>
          <TextField label='' type='number' value={valueAdjust} onChange={(e) => { setValueAdjust(Number(e.target.value)) }} />
        </Box>
        {/* <Button disabled={valueAdjust === 0 || loading} onClick={handleSave}
          variant='contained' color='primary' sx={{ marginTop: '20px' }}>
          Guardar
        </Button> */}
        <LoadingButton
          sx={{ marginTop: '20px' }}
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          disabled={valueAdjust === 0 || loading}
          variant='contained'
          color='primary'
        >
          Guardar
        </LoadingButton>
      </Box>
    </Box>
  )
}
