import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { PAYMENT_PERCENTAGE } from '../../constants'

interface StudentPaymentSummary {
  totalStudents: number
  totalStudentsWithPayment: number
  totalStudentsWithoutPayment: number
  totalTeachers: number
}

export interface PaymentPercentageState {
  loading: boolean
  error: string
  data: StudentPaymentSummary | null
  success: boolean
}

const initialState: PaymentPercentageState = {
  loading: false,
  error: '',
  data: null,
  success: false
}

const paymentPercentageReducer = createSlice({
  name: 'paymentPercentageReducer',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(PAYMENT_PERCENTAGE.PAYMENT_PERCENTAGE_LOADING, state => {
        state.loading = true
      })
      .addCase(PAYMENT_PERCENTAGE.PAYMENT_PERCENTAGE_SUCCESS, (state, action: PayloadAction<StudentPaymentSummary>) => {
        state.loading = false
        state.success = true
        state.data = action.payload
      })
      .addCase(PAYMENT_PERCENTAGE.PAYMENT_PERCENTAGE_ERROR, (state, action: PayloadAction<string>) => {
        state.loading = false
        state.error = action.payload
      })
  },
  reducers: {
    resetStateBooleans: state => {
      state.loading = false
      state.error = ''
      state.success = false
    },
    resetStateData: state => initialState
  }
})

export const { resetStateBooleans, resetStateData } = paymentPercentageReducer.actions
export default paymentPercentageReducer.reducer
