import { Stack, Typography, Box, AppBar, Toolbar, IconButton } from '@mui/material'
import { Search as SearchIcon, Notifications as NotificationsIcon, Search } from '@mui/icons-material'
import { CardUser } from '../../../components'
import { Link } from 'react-router-dom'
import { SearchIconWrapper, StyledInputBase } from '../ClassroomPlaner/ClassRooomPlaner'

const exampleMembers: any[] = [
  {
    name: 'Henry Paulists',
    email: 'henry.p@gmail.com',
    progress: 100,
    avatarUrl: '/placeholder.svg?height=56&width=56',
    role: ''
  },
  {
    name: 'Evan Jefferson',
    email: 'jefferson@gmail.com',
    progress: 82,
    avatarUrl: '/placeholder.svg?height=56&width=56',
    role: ''
  },
  {
    name: 'Marh Tomos',
    email: 'marh.t@gmail.com',
    progress: 66,
    avatarUrl: '/placeholder.svg?height=56&width=56',
    role: ''
  }
]

const StudentsView = (): JSX.Element => {
  if (!exampleMembers || exampleMembers.length === 0) {
    return <Typography>No team members to display.</Typography>
  }

  return (
    <>
    <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Estudiantes
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <IconButton size="large" aria-label="show notifications" color="inherit">
            <NotificationsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    <Box sx={{ padding: 2 }}>
      <Stack direction="row" flexWrap="wrap" justifyContent="center">
        {exampleMembers.map((member, index) => (
          <Link key={index} to={'/student-act/'} style={{ textDecoration: 'none' }}>
            <CardUser key={index} member={member} />
          </Link>
        ))}
      </Stack>
    </Box>
    </>
  )
}

export default StudentsView
