export const PLANNINGS = {
  FETCH_PLANNINGS: 'FETCH_PLANNINGS',
  FETCH_PLANNINGS_SUCCESS: 'FETCH_PLANNINGS_SUCCESS',
  FETCH_PLANNINGS_ERROR: 'FETCH_PLANNINGS_ERROR',
  FETCH_PLANNING: 'FETCH_PLANNING',
  FETCH_PLANNING_SUCCESS: 'FETCH_PLANNING_SUCCESS',
  FETCH_PLANNING_ERROR: 'FETCH_PLANNING_ERROR',
  CREATE_PLANNING: 'CREATE_PLANNING',
  CREATE_PLANNING_SUCCESS: 'CREATE_PLANNING_SUCCESS',
  CREATE_PLANNING_ERROR: 'CREATE_PLANNING_ERROR',
  UPDATE_PLANNING: 'UPDATE_PLANNING',
  UPDATE_PLANNING_SUCCESS: 'UPDATE_PLANNING_SUCCESS',
  UPDATE_PLANNING_ERROR: 'UPDATE_PLANNING_ERROR',
  DELETE_PLANNING: 'DELETE_PLANNING',
  DELETE_PLANNING_SUCCESS: 'DELETE_PLANNING_SUCCESS',
  DELETE_PLANNING_ERROR: 'DELETE_PLANNING_ERROR',
  CLEAR_PLANNING: 'CLEAR_PLANNING',
  CLEAR_PLANNINGS: 'CLEAR_PLANNINGS'
}
