import { Typography, Container, Box } from '@mui/material'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../redux/hook'
import { redirect } from '../../redux/actions'

const Redirect = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const redirectLink = async (): Promise<any> => {
    const linkToredirect = await dispatch(redirect(location.pathname))
    if (linkToredirect.status === 200) {
      navigate(linkToredirect.data.redirect)
    }
  }

  useEffect(() => {
    void redirectLink()
  }, [redirectLink])

  return (
    <Container>
      <Box>
        <Typography variant="subtitle1">
          Espere un momento, estamos redirigiendo...
        </Typography>
      </Box>
    </Container>
  )
}

export default Redirect
