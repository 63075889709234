import { Avatar, Box, Typography, styled as styledM } from '@mui/material'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useParams, useLocation } from 'react-router-dom'
import { useStudent } from '../../hooks'
import { TabPanel, Tabs, ViewStack, Skeleton, Title } from '../../components'
import mascota from '../../assets/images/mascota.png'

const Section = styled.div`
  width: 100%;
  margin-bottom: 19px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 0 15px;
`

const SectionTitle = styledM(Typography)<any>(({ theme, color }) => ({
  padding: theme.spacing(1.0),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: color ?? theme.palette.text.primary
}))

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #808080;
`

const InfoLabel = styledM(Typography)<any>(({ theme, color }) => ({
  color: color ?? theme.palette.text.primary,
  fontWeight: 'bold'
}) as any)

export const InfoValue = styledM(Typography)<any>(({ theme, color }) => ({
  color: color ?? theme.palette.text.primary,
  display: 'flex',
  gap: '5px'
}) as any)

const Student = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { data: user, loading, error } = useStudent(String(id))
  const [value, setValue] = useState(0)

  const useQuery = (): any => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const getTwoQueryParams = (param: string): string => {
    const query = useQuery()
    return query.get(param) || ''
  }

  const group = useQuery().get('group_id')
  const mainId = getTwoQueryParams('mainId')

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  if (!user && !loading && !error) return <Skeleton />
  if (error) return <div>Error: {error}</div>

  return (
    <ViewStack sx={{ padding: '0 12px', flexDirection: 'column' }} alignItems='center'>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
        <Avatar sx={{ width: 170, height: 170, fontSize: 35, backgroundColor: '#65b2d6' }} src={mascota} sizes='large' imgProps={{ sx: { objectFit: 'scale-down', width: '90%' } }} />
      </Box>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <Title title="Expediente del Alumno:" variant="h4" />
      </div>
      <Box flexWrap={
        { xs: 'wrap' }
      } sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', width: '100%' }}>
        <Tabs labels={['Información Personal', 'Información del Grupo', 'Información de Salud']}
          handleChange={handleChange} value={value} setValue={setValue} >
          <TabPanel value={value} index={0}>
            <Section>
              <SectionTitle>Información Personal</SectionTitle>
              <InfoRow>
                <InfoLabel>Nombre:</InfoLabel>
                {user.group_id === null ? <InfoValue>{user.students.map((student: any) => student.curp === mainId ? `${student.name} ${student.lastName}` : '').join('')}</InfoValue> : <InfoValue>{user.name} {user.lastName}</InfoValue>}
                {/* */}
              </InfoRow>
              <InfoRow>
                <InfoLabel>Email:</InfoLabel>
                <InfoValue>{user.email}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Teléfono:</InfoLabel>
                <InfoValue>{user.phone}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Domicilio:</InfoLabel>
                <InfoValue>{user.address}</InfoValue>
              </InfoRow>
            </Section>
            <Section>
              <SectionTitle>Información del Padre, Madre o Tutor</SectionTitle>
              <InfoRow>
                <InfoLabel>Padre:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.father?.name : 'No tiene padre registrado'}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Estado Civil del Padre:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.father?.civilStatus : 'No tiene padre registrado'}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Madre:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.mother?.name : 'No tiene madre registrada'}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Estado Civil:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.mother?.civilStatus : 'No tiene madre registrada'}</InfoValue>
              </InfoRow>
            </Section>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Section>
              <SectionTitle>Información del Grupo</SectionTitle>
              <InfoRow>
                <InfoLabel>Grado:</InfoLabel>
                <InfoValue>
                  { user.group_id === null
                    ? user.students
                      .filter((student: any) => student.group_id._id === group)
                      .map((student: any) => student.group_id.grade)
                      .join('') || 'No asignado'
                    : user.group_id.grade }
                </InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Grupo:</InfoLabel>
                <InfoValue>
                  { user.group_id === null
                    ? user.students
                      .filter((student: any) => student.group_id._id === group)
                      .map((student: any) => student.group_id.group)
                      .join('') || 'No asignado'
                    : user.group_id.group }
                </InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Nombre del Grupo:</InfoLabel>
                <InfoValue>
                  {user.group_id === null
                    ? user.students
                      .filter((student: any) => student.group_id._id === group)
                      .map((student: any) => student.group_id.name)
                      .join('') || 'No asignado'
                    : user.group_id.name}
                </InfoValue>
              </InfoRow>
            </Section>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Section>
              <SectionTitle>Información de Salud</SectionTitle>
              <InfoRow>
                <InfoLabel>Tipo de Sangre:</InfoLabel>
                <InfoValue>{user.group_id === null ? <InfoValue>{user.students.map((student: any) => student.group_id._id === group ? student.healthDetailsDto.bloodType : '').join('')}</InfoValue> : <InfoValue>{user.students[0].healthDetailsDto.bloodType}</InfoValue>}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Alergias:</InfoLabel>
                <InfoValue>{user.group_id === null ? <InfoValue>{user.students.map((student: any) => student.group_id._id === group ? student.healthDetailsDto.alergies : '').join('')}</InfoValue> : <InfoValue>{user.students[0].healthDetailsDto.alergies}</InfoValue>}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Estatura:</InfoLabel>
                <InfoValue>{user.group_id === null ? <InfoValue>{user.students.map((student: any) => student.group_id._id === group ? student.healthDetailsDto.size : '').join('')}</InfoValue> : <InfoValue>{user.students[0].healthDetailsDto.size}</InfoValue>}cm</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Peso:</InfoLabel>
                <InfoValue>{user.group_id === null ? <InfoValue>{user.students.map((student: any) => student.group_id._id === group ? student.healthDetailsDto.weight : '').join('')}</InfoValue> : <InfoValue>{user.students[0].healthDetailsDto.weight}</InfoValue>}kg</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Usa Lentes:</InfoLabel>
                <InfoValue>{user.group_id === null ? <InfoValue>{user.students.map((student: any) => student.group_id._id === group ? student.healthDetailsDto.wearsGlasses ? 'Sí' : 'No' : '').join('')}</InfoValue> : <InfoValue>{user.healthDetailsDto.usesGlasses ? 'Sí' : 'No'}</InfoValue>}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Limitación Física:</InfoLabel>
                <InfoValue>{user.hasLimitation.physical ? user.hasLimitation.description : 'No'}</InfoValue>
              </InfoRow>
            </Section>
          </TabPanel>
        </Tabs>
      </Box>
    </ViewStack>
  )
}

export default Student
