import { type PaletteMode } from '@mui/material'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface DarkMode {
  isDarkMode: PaletteMode
}

const initialState: DarkMode = {
  isDarkMode: 'dark'
}

const darkMode = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<PaletteMode>) => {
      state.isDarkMode = action.payload
      localStorage.setItem('isDarkMode', action.payload)
    }
  }
})

export const { setDarkMode } = darkMode.actions

export default darkMode.reducer
