import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { darkMode, authReducer, usersReducer, groupsReducer, createGrpsReducer, groupReducer, studentReducer, createStudentGrpsReducer, generateLinkReducer, grpsOnlyReducer, teachersReducer, messageSnackbarReducer, getPlanningsReducer, updateStudentReducer, getStudentsReducer, paymentPercentageReducer, teachersReducerGroups } from './reducer'

export const store = configureStore({
  reducer: {
    darkMode,
    authReducer,
    usersReducer,
    groupsReducer,
    createGrpsReducer,
    groupReducer,
    studentReducer,
    createStudentGrpsReducer,
    generateLinkReducer,
    grpsOnlyReducer,
    teachersReducer,
    messageSnackbarReducer,
    getPlanningsReducer,
    updateStudentReducer,
    getStudentsReducer,
    paymentPercentageReducer,
    teachersReducerGroups
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
