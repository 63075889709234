import { Box } from '@mui/joy'
import { StyledTitle } from './style/styles'

const Title = ({ title, variant, color }: any): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <StyledTitle color={color} variant={variant}>
        {title}
      </StyledTitle>
    </Box>
  )
}

export default Title
