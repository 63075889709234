import { type FormikValues } from 'formik'
import { Grid, Typography, Box } from '@mui/material'
import { InputC } from '../../../components'

const EmergencyForm = ({ formik }: FormikValues): JSX.Element => {
  return (
    <>
    <Box >
      <Typography variant="h5" component="h4" mb={5}>
        Contacto de emergencia
      </Typography>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={6}>
            <InputLabel id="emergency_contacts">Selecciona una opción</InputLabel>
              <Select defaultValue="" labelId="emergency_contacts" id="emergency_contacts" fullWidth variant="outlined" onChange={formik.handleChange} name='emergency_contacts' value={formik.values.emergency_contacts}>
                <MenuItem value="" disabled selected>
                  Selecciona una opción
                </MenuItem>
                <MenuItem value={'mother'}>
                  Madre
                </MenuItem>
                <MenuItem value={'father'}>
                  Padre
                </MenuItem>
                <MenuItem value={'other'}>
                  Ambos
                </MenuItem>
              </Select>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <InputC
              label="Nombre"
              name="emergencyContact.name"
              type="text"
              value={formik.values.emergencyContact.name}
              onChange={formik.handleChange}
              placeholder='Ingresa nombre completo'
            />
            <InputC
              label="Teléfono"
              name="emergencyContact.phone"
              type="text"
              value={formik.values.emergencyContact.phone}
              onChange={formik.handleChange}
              placeholder='Ingresa el teléfono'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Correo Electrónico"
              name="emergencyContact.email"
              type="text"
              value={formik.values.emergencyContact.email}
              onChange={formik.handleChange}
              placeholder='Ingresa el correo electrónico'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Dirección"
              name="emergencyContact.address"
              type="text"
              value={formik.values.emergencyContact.address}
              onChange={formik.handleChange}
              placeholder='Ingresa la dirección'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputC
              label="Parentesco"
              name="emergencyContact.relationship"
              type="text"
              value={formik.values.emergencyContact.relationship}
              onChange={formik.handleChange}
              placeholder='Ingresa el parentesco'
            />
          </Grid>
      </Grid>
    </Box>
    </>

  )
}

export default EmergencyForm
