import { type ViewStackProps } from '@/components/ViewStack/styles/styles'
import { Box } from '@mui/joy'
import { styled } from '@mui/material'

export const ViewStackUi = styled(Box)<ViewStackProps>(({ theme, backgroundColor }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100vh',
  width: '100%',
  backgroundColor: backgroundColor ?? '#ffff',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    background: 'none',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
